import { Storage } from '../classes/storage';
import { TEntityEditMethod } from '../common';

// Entities

export type TStringOrNumber = string | number;
export type TStringOrNull = string | null;
export type TNumberOrNull = number | null;
export type TBooleanOrNull = boolean | null;
export type TObjectWithMandatoryFields<T extends TObjectType, Q extends keyof T> = Partial<T> & Pick<T, Q>;
export type TEntityId<T extends TStringOrNumber> = T;

export type IDictionary<K extends TStringOrNumber, T> = {
	[id in K]: T | undefined;
};

export interface IInvalidatedTags {
	type: string;
	id: TStringOrNumber;
}

export interface IEntity<K extends TStringOrNumber, T> {
	ids: TEntityId<K>[];
	entities: IDictionary<K, T>;
}

export type TColorTheme = 'light' | 'dark';

export type TObjectType = { [key: string]: any };

export const QUERY_TAGS = {
	user: 'user',
	unit: 'units',
	project: 'project',
	project_data: 'project_data',
	project_content: 'project_content',
	project_relationship: 'project_relationship',
	entity: 'entity',
	relationship: 'relationship',
};
/**
 * Storage
 */

interface IPhotonStorage {
	theme: TColorTheme;
}
export const storage = new Storage<IPhotonStorage>('photon');

export const updateThemeInPage = (theme?: TColorTheme) => {
	if (!theme) theme = (storage.get('theme') || 'light') as TColorTheme;
	document.documentElement.dataset.bsTheme = theme;
};

export interface IEntityEditMethod {
	method: TEntityEditMethod;
}

export type TMutatingRow<T, F extends keyof T> = Partial<T> & Pick<T, F>;

export interface ISelectOption {
	label: string;
	value: string;
}

export interface IUpdateResponse<T extends string | number> {
	action: 'edit' | 'insert' | 'delete';
	type: 'entity' | 'relationship' | 'project';
	id: T;
}

export interface IProjectRights {
	is_creator: TBooleanOrNull;
	is_manager: TBooleanOrNull;
	is_editor: boolean;
	is_meta_editor: boolean;
}

export interface IProjectUserRight extends IProjectRights {
	project_id: number;
	user_id: TNumberOrNull;
	user_email: string;
	start_date: string;
	end_date: TStringOrNull;
}

/**
 * Тут назва поля "selected" тому, що первинно даний інтерфейс використовувався
 * для роботи з наборами checkboxes. Наразі він використовується для роботи
 * з масивами будь-яких об'єктів, але назва лишилась стара.
 */
export interface IArrayDataOptions<T> {
	modified: boolean;
	selected: T[];
}
export type ITextArrayDataOptions = IArrayDataOptions<TEntityId<string>>;
