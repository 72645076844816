import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import styled from 'styled-components';
import { TStringOrNull } from '../../interfaces/common';
import { moveControlToScreenPosition } from '../../scrolls';
import TextIcon from '../TextIcon';

interface IDescriptionTextProps extends React.AllHTMLAttributes<HTMLDivElement> {
	description?: TStringOrNull;
	maxHeight?: string;
	iconOnly?: boolean;
	scrollPosition?: ScrollLogicalPosition;
	variant?: string;
}
const DescriptionText = ({
	description = '',
	maxHeight,
	className,
	iconOnly,
	scrollPosition,
	variant = 'info',
	...rest
}: IDescriptionTextProps) => {
	const [collapsed, setCollapsed] = useState(maxHeight !== undefined);
	const [withInteraction, setWithInteraction] = useState(false);
	const refContainer = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!refContainer.current || !collapsed) return;
		setWithInteraction(refContainer.current.clientHeight < refContainer.current.scrollHeight);
	}, [collapsed, description]);

	useEffect(() => {
		setCollapsed(maxHeight !== undefined);
	}, [description, maxHeight]);

	const onSetHeightClick = () => {
		setCollapsed((collapsed) => !collapsed);
		if (scrollPosition)
			setTimeout(() => {
				moveControlToScreenPosition(refContainer.current, scrollPosition);
			}, 100);
	};

	return (
		<DescriptionContainer
			$maxHeight={collapsed ? maxHeight : undefined}
			className={classNames(className, 'position-relative overflow-hidden')}
			ref={refContainer}
			{...rest}
		>
			{(description || '-')
				.split('\n')
				.filter(Boolean)
				.map((paragraph, index) => (
					<p key={index}>{paragraph}</p>
				))}
			{withInteraction && (
				<MoreButtonContainer
					className={classNames('position-absolute bottom-0 w-100 text-end py-1 z-1', collapsed && 'bg-gradient')}
				>
					<Button
						variant={variant}
						size="sm"
						onClick={onSetHeightClick}
						title={iconOnly ? (collapsed ? 'Більше' : 'Менше') : undefined}
					>
						{collapsed ? (
							<TextIcon Icon={BsChevronDown}>{!iconOnly && <>Більше</>}</TextIcon>
						) : (
							<TextIcon Icon={BsChevronUp}>{!iconOnly && <>Менше</>}</TextIcon>
						)}
					</Button>
				</MoreButtonContainer>
			)}
		</DescriptionContainer>
	);
};

export default DescriptionText;

const DescriptionContainer = styled.div<{ $maxHeight?: string }>`
	max-height: ${(props) => `${props.$maxHeight ?? 'unset'}`};
	p {
		margin-bottom: 0.25rem;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;
const MoreButtonContainer = styled.div`
	--bs-gradient: linear-gradient(to bottom, transparent, var(--card-bg, var(--bs-body-bg)));
`;
