import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { BsCrosshair } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { YYYYMMDD } from '../../../common';
import SearchForm from '../../../components/SearchForm';
import TextIcon from '../../../components/TextIcon';
import { RootState } from '../../../store';
import { isPeriodValid } from '../../../utils';
import { changeProjectSearchParams } from '../projectSlice';

interface ISearchProjectPanelProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const SearchProjectPanel = ({
	params: { term, startDate, endDate, isActive, isEditable, targetsOnly },
	changeProjectSearchParams,
	disabled = false,
}: ISearchProjectPanelProps) => {
	const [periodIsValid, setPeriodIsValid] = useState(isPeriodValid(startDate, endDate));

	useEffect(() => {
		setPeriodIsValid(isPeriodValid(startDate, endDate));
	}, [startDate, endDate]);
	const onTermChange = (term: string) => {
		changeProjectSearchParams({ term });
	};

	const onChangeParam = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.type !== 'checkbox' ? event.target.value : event.target.checked;
		changeProjectSearchParams({ [name]: value });
	};

	const maxDate = dayjs().format(YYYYMMDD);
	return (
		<>
			<Row>
				<SearchForm
					formId="fmProjectSearch"
					className="col-12 mb-2"
					initialTerm={term}
					onSubmit={onTermChange}
					focusOnInit
				>
					<div className="input-group-text">
						<Form.Check
							type="checkbox"
							label={
								<TextIcon Icon={BsCrosshair} iconLast className="text-primary">
									Тільки
								</TextIcon>
							}
							id="psTargetsOnly"
							name="targetsOnly"
							checked={targetsOnly}
							onChange={onChangeParam}
							className="mb-0"
							disabled={disabled}
							title="Пошук тільки серед об'єкті запитів, або серед всіх об'єктів, що входять до проектів"
						/>
					</div>
				</SearchForm>
			</Row>
			<Row className="justify-content-center align-items-center row-gap-1">
				<Col xs="auto">
					<Form.Group controlId="psStartDate" className="hstack gap-2">
						<Form.Label className="text-nowrap mb-0">Початкова дата</Form.Label>
						<Form.Control
							name="startDate"
							min="2024-01-01"
							max={maxDate}
							type="date"
							required
							placeholder="Введіть початкову дату"
							value={startDate}
							onChange={onChangeParam}
							autoComplete="off"
							className={periodIsValid ? undefined : 'is-invalid'}
							disabled={disabled}
						/>
					</Form.Group>
				</Col>
				<Col xs="auto">
					<Form.Group controlId="psEndDate" className="hstack gap-2">
						<Form.Label className="text-nowrap mb-0">Кінцева дата</Form.Label>
						<Form.Control
							name="endDate"
							min="2024-01-01"
							max={maxDate}
							type="date"
							required
							placeholder="Введіть початкову дату"
							value={endDate}
							onChange={onChangeParam}
							autoComplete="off"
							className={periodIsValid ? undefined : 'is-invalid'}
							disabled={disabled}
						/>
					</Form.Group>
				</Col>
				<Col xs="auto">
					<Form.Check
						type="checkbox"
						label="Можу редагувати"
						id="psIsEditable"
						name="isEditable"
						checked={isEditable}
						onChange={onChangeParam}
						disabled={disabled}
					/>
				</Col>
				<Col xs="auto">
					<Form.Check
						type="checkbox"
						label="Тільки активні"
						id="psIsActive"
						name="isActive"
						checked={isActive}
						onChange={onChangeParam}
						disabled={disabled}
					/>
				</Col>
			</Row>
		</>
	);
};

const mapState = (state: RootState) => ({
	params: state.project.projectSearchParams,
});

const mapDispatch = { changeProjectSearchParams };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SearchProjectPanel);
