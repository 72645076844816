import classNames from 'classnames';
import { useCallback } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';
import styled from 'styled-components';
import { showPromiseConfirm } from '../../alerts';
import { getRelationshipImageSrc } from '../../utils';

interface IRelationshipImageEditorProps {
	src: string;
	editable?: boolean;
	onDelete?: (src: string) => void;
	className?: string;
	isLoading?: boolean;
}
const RelationshipImageEditor = ({ src, editable, onDelete, className, isLoading }: IRelationshipImageEditorProps) => {
	const onDeleteClick = useCallback(
		async (event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();
			if (onDelete && (await showPromiseConfirm("Ви дійсно бажаєте видалити дане приєднане до зв'язку зображення?")))
				onDelete(src);
		},
		[onDelete, src]
	);

	const realSrc = getRelationshipImageSrc(src);
	return (
		<div className="rel-image__item">
			<LogoImage
				className={classNames('w-10a0 rounded border border-2 position-relative img-zoom-able', className)}
				$src={realSrc}
				data-src={realSrc}
			>
				{editable && (
					<ButtonGroup size="sm" className="position-absolute top-0 end-0 mt-2 me-2">
						<Button variant="danger" onClick={onDeleteClick} title="Видалити" disabled={isLoading}>
							<BsTrash />
						</Button>
					</ButtonGroup>
				)}
			</LogoImage>
		</div>
	);
};

export default RelationshipImageEditor;

interface IRelationshipImage {
	$src: string;
}
const LogoImage = styled.div<IRelationshipImage>`
	padding-bottom: 75%;
	background: ${({ $src }) => `url(${$src}) center / cover no-repeat`};
`;
