import { Col, Container, Row } from 'react-bootstrap';
import { BsFolder, BsPlusCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { DEFAULTS } from '../../common';
import { FixedDotSpinner } from '../../components/DotSpinner';
import FetchError from '../../components/FetchError';
import HeaderWithImage from '../../components/HeaderWithImage';
import QuestionButton from '../../components/QuestionButton';
import TextIcon from '../../components/TextIcon';
import WithUnits, { IWithUnits } from '../../components/hoc/withUnits';
import ImgVoid from '../../img/undraw_project_complete_lwss.svg';
import { selectAppStore, useAppSelector } from '../../store';
import { GridAutoFill } from '../../styledComponents';
import { useRecentProjectsQuery } from '../services/project';
import RecentProject from './recentProject';

interface IRecentProjectListProps extends IWithUnits {}
const RecentProjectList = ({ units }: IRecentProjectListProps) => {
	const { data: projects, error, isFetching } = useRecentProjectsQuery();
	const { userRights } = useAppSelector(selectAppStore);
	return (
		<Container fluid>
			<Row>
				<Col>
					<h1 className="hstack gap-2 justify-content-center align-items-center position-relative">
						<TextIcon Icon={BsFolder} className="mt-n1">
							Останні проекти
						</TextIcon>
						<QuestionButton
							title="Проекти, створені за місяць, або ті, що є активними, які Ви можете редагувати"
							className="me-1"
						/>
						{!isFetching && <span className="badge rounded-pill text-bg-info fs-5">{projects?.length}</span>}
						{userRights?.isEditor && (
							<Link
								to={`${DEFAULTS.routes.project}/0/${DEFAULTS.routes.data}`}
								className="btn btn-primary position-absolute end-0 mt-1"
							>
								<TextIcon Icon={BsPlusCircle} size="lg">
									Новий проект
								</TextIcon>
							</Link>
						)}
					</h1>
				</Col>
				{error && <FetchError error={error} />}
			</Row>
			{projects?.length === 0 && (
				<HeaderWithImage
					imgSrc={ImgVoid}
					// alt="Зображення відсутності результатів"
					headerClassName="text-info"
					title="У Вас поки що відсутні останні проекти"
					imageFirst
				/>
			)}
			{projects && (
				<Row className="mb-3">
					<GridAutoFill className="col-12 align-items-start" $baseSize="450px">
						{projects.map((project) => (
							<RecentProject key={project.project_id} project={project} unitsById={units.entities} />
						))}
					</GridAutoFill>
				</Row>
			)}
			{isFetching && <FixedDotSpinner>Завантаження інформації про останні проекти...</FixedDotSpinner>}
		</Container>
	);
};

export default WithUnits(RecentProjectList);
