import React from 'react';
import { Col } from 'react-bootstrap';
import { BsCrosshair } from 'react-icons/bs';
import styled from 'styled-components';
import NatoCountryFlag from '../../components/NatoCountryFlag';
import DescriptionText from '../../components/controls/DescriptionText';
import WithDictionaries, { IWithDictionaries } from '../../components/hoc/withDictionaries';
import { IDictionary } from '../../interfaces/common';
import { IRelationshipListItem } from '../../interfaces/relationship';
import { EntityLogo } from '../../styledComponents';
import EntityTitleWithType from '../entities/EntityTitleWithType';
import { ISummaryEntity, ISummaryEntityRelationship } from '../services/project';
import EntitySummaryRelationshipList from './EntitySummaryRelationshipList';

interface IEntitySummaryProps extends IWithDictionaries, React.AllHTMLAttributes<HTMLDivElement> {
	serialNo: number;
	entityId: number;
	entities: IDictionary<number, ISummaryEntity>;
	relationships: IDictionary<string, IRelationshipListItem & { images: string[] }>;
	entityRelationships?: ISummaryEntityRelationship[];
}
const EntitySummary = ({
	serialNo,
	entityId,
	entities,
	entityTypes: { entities: entityTypes },
	relationships,
	entityRelationships,
}: IEntitySummaryProps) => {
	const entity = entities[entityId];
	if (!entity) return <div className="text-danger">Can't find entity {entityId}</div>;
	return (
		<EntitySection className="row mb-4">
			<Col xs={12}>
				<h3 className="text-center border-bottom border-4" id={`card_entity_${entityId}`}>
					{serialNo}. {entity.is_init && <BsCrosshair className="me-1 mt-n1" />}
					<EntityTitleWithType typeId={entity.entity_type} title={entity.title} />
					<span className="ms-2 text-muted text-small">({entityTypes[entity.entity_type]?.title})</span>
				</h3>
				<div className="d-flex gap-3">
					<EntityLogo $size="20vw" entity={entity} className="img-thumbnail flex-shrink-0" />
					<div>
						<div className="mb-1">
							<strong>Країна:</strong> <NatoCountryFlag trigram={entity.country} withTitle />
						</div>
						{entity.alternativeTitles.length !== 0 && (
							<div className="mb-1">
								<strong>Альтернативні назви:</strong> {entity.alternativeTitles.join(' • ')}
							</div>
						)}
						<DescriptionText description={entity.description} />
					</div>
				</div>
			</Col>
			{entityRelationships && (
				<Col xs={12}>
					<EntitySummaryRelationshipList
						entityId={entityId}
						entities={entities}
						relationships={relationships}
						entityRelationships={entityRelationships}
					/>
				</Col>
			)}
		</EntitySection>
	);
};

export default WithDictionaries(EntitySummary);

const EntitySection = styled.section`
	p {
		font-family: var(--bs-font-serif);
		text-align: justify;
	}
`;
