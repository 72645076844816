import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { BsFolderFill } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DEFAULTS } from '../../common';
import SearchForm from '../../components/SearchForm';
import { RootState } from '../../store';
import { getRegexpForString } from '../../utils';
import { changeEntitySearchParams, globalNetworkState } from '../projects/projectSlice';

interface IEntitySearchPanelProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLInputElement> {
	noNavigate?: boolean;
	focusOnInit?: boolean;
	inPlaceSearch?: boolean;
}
const EntitySearchPanel = ({
	noNavigate,
	focusOnInit,
	params: { term, inProjectOnly },
	changeEntitySearchParams,
	className,
	id = 'fmEntitySearch',
	inPlaceSearch,
}: IEntitySearchPanelProps) => {
	const navigate = useNavigate();

	const onInProjectChange = useCallback(() => {
		changeEntitySearchParams({ inProjectOnly: !inProjectOnly });
	}, [inProjectOnly, changeEntitySearchParams]);

	const onSearch = useCallback(
		(term: string) => {
			changeEntitySearchParams({ term });
			if (noNavigate || !term) return;
			navigate(DEFAULTS.routes.search, { state: DEFAULTS.origins.basic });
		},
		[changeEntitySearchParams, navigate, noNavigate]
	);

	const onTermChange = useCallback(
		(newTerm: string) => {
			const re = newTerm && inProjectOnly ? getRegexpForString(newTerm) : null;
			globalNetworkState?.nodes.forEach((node) => {
				const { id, label } = node;
				if (!id) return;
				globalNetworkState?.nodes.updateOnly({ id, opacity: !re || re.test(label ?? '-') ? 1 : 0.2 });
			});
		},
		[inProjectOnly]
	);

	return (
		<SearchForm
			formId={id}
			className={className}
			// variant="secondary"
			onSubmit={onSearch}
			initialTerm={term}
			focusOnInit={focusOnInit}
			withClearButton={inPlaceSearch}
			onChange={inPlaceSearch ? onTermChange : undefined}
		>
			<div className="input-group-text">
				<Form.Check
					type="checkbox"
					label={<BsFolderFill className="text-primary" />}
					id="osInProjectOnly"
					// name="inProjectOnly"
					checked={inProjectOnly}
					onChange={onInProjectChange}
					className="mb-0"
					title="Пошук тільки серед об'єкті проекту"
				/>
			</div>
		</SearchForm>
	);
};

const mapState = (state: RootState) => ({
	params: state.project.entitySearchParams,
});

const mapDispatch = {
	changeEntitySearchParams,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EntitySearchPanel);
