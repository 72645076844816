import classNames from 'classnames';
import { IconType } from 'react-icons';
import {
	BsArchive,
	BsBank,
	BsBell,
	BsBox,
	BsBoxes,
	BsBuilding,
	BsCashCoin,
	BsCoin,
	BsDiagram3,
	BsFileText,
	BsFlag,
	BsGearWideConnected,
	BsGlobe,
	BsIncognito,
	BsMegaphone,
	BsMortarboard,
	BsNewspaper,
	BsPeople,
	BsPerson,
	BsPersonStanding,
	BsPersonVcard,
	BsQrCode,
} from 'react-icons/bs';

const ENTITY_TYPES: Record<number, IconType> = {
	1: BsQrCode,
	2: BsPeople,
	3: BsBank,
	4: BsPersonStanding,
	5: BsFileText,
	6: BsMortarboard,
	7: BsNewspaper,
	8: BsGlobe,
	9: BsArchive,
	10: BsPersonVcard,
	11: BsIncognito,
	12: BsBuilding,
	13: BsDiagram3,
	14: BsPerson,
	15: BsCashCoin,
	16: BsGearWideConnected,
	17: BsMegaphone,
	18: BsFlag,
	19: BsBell,
	20: BsCoin,
	21: BsBoxes,
};

interface IEntityTypeImageProps extends React.HTMLAttributes<HTMLDivElement> {
	entityTypeId?: number;
	lg?: boolean;
}
const EntityTypeImage = ({ entityTypeId, className, lg }: IEntityTypeImageProps) => {
	if (!entityTypeId) return null;
	const Icon = ENTITY_TYPES[entityTypeId] ?? BsBox;
	return <Icon className={classNames(className, 'entity-icon', lg && 'icon-lg')} />;
};

export default EntityTypeImage;
