import React from 'react';
import EntityTypeImage from '../../components/EntityTypeImage';

interface IEntityTitleWithTypeProps extends React.AllHTMLAttributes<HTMLDivElement> {
	typeId: number;
}
const EntityTitleWithType = ({ title, className, typeId }: IEntityTitleWithTypeProps) => {
	return (
		<span className={className}>
			{title} <EntityTypeImage entityTypeId={typeId} className="mt-n1" />
		</span>
	);
};

export default EntityTitleWithType;
