import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { Network } from 'vis-network/standalone';
import { DEFAULTS, YYYYMMDD } from '../../common';
import { IEntitySearchParams } from '../../interfaces/entities';
import { IProjectSearchParams } from '../../interfaces/projects';
import { DataSetFullEdges, DataSetFullNodes } from '../../schemeUtils';

const getDefaultSearchDates = () => ({
	startDate: dayjs().subtract(1, 'month').format(YYYYMMDD),
	endDate: dayjs().format(YYYYMMDD),
});

const DEFAULT_PROJECT_SEARCH_PARAMS: IProjectSearchParams = {
	term: '',
	isEditable: true,
	isActive: false,
	targetsOnly: true,
	pageNo: 0,
	...getDefaultSearchDates(),
};

export interface INetworkState {
	network: Network;
	nodes: DataSetFullNodes;
	edges: DataSetFullEdges;
}
export let globalNetworkState: INetworkState | null = null;
export const changeGlobalNetworkState = (newNetworkState: INetworkState | null) => {
	globalNetworkState = newNetworkState;
};

interface IProjectSearchMetadata {
	totalProjects: number;
	totalPages: number;
	currentPageNo: number;
}
/**
 * Позаяк неможливо серіалізувати INetworkState, будемо серіалізувати час її зміни
 * (щоб це можна було впіймати в залежних компонентах), а сам стан будемо зберігати
 * у глобальній змінній.
 */
export interface IProjectSlice {
	projectSearchParams: IProjectSearchParams;
	projectSearchMetadata: IProjectSearchMetadata;
	networkStateTs: number;
	entitySearchParams: IEntitySearchParams;
}
const initialState: IProjectSlice = {
	projectSearchParams: { ...DEFAULT_PROJECT_SEARCH_PARAMS },
	projectSearchMetadata: {
		totalProjects: 0,
		totalPages: 0,
		currentPageNo: 0,
	},
	networkStateTs: new Date().valueOf(),
	entitySearchParams: { term: '', inProjectOnly: false },
};
export const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		clearProjectSearchParams: (state, action: PayloadAction<void>) => {
			// Тут треба пам'ятати про дати
			state.projectSearchParams = { ...DEFAULT_PROJECT_SEARCH_PARAMS, ...getDefaultSearchDates() };
		},
		changeProjectSearchParams: (state, action: PayloadAction<Partial<IProjectSearchParams>>) => {
			// При зміні будь-якого параметру буде пошук буде виконуватись заново,
			// тому скидаємо номер сторінки
			state.projectSearchParams = { ...state.projectSearchParams, ...action.payload, pageNo: 0 };
		},
		changeProjectSearchPageNo: (state, action: PayloadAction<number>) => {
			if (action.payload < 0 || state.projectSearchMetadata.totalPages <= action.payload) return;
			state.projectSearchParams = { ...state.projectSearchParams, pageNo: action.payload };
		},
		changeProjectSearchMetadataProjects: (state, action: PayloadAction<number>) => {
			state.projectSearchMetadata = {
				totalProjects: action.payload,
				totalPages: Math.ceil(action.payload / DEFAULTS.projectsPerPage),
				currentPageNo: 0,
			};
		},
		changeProjectSearchMetadataCurrent: (state, action: PayloadAction<number>) => {
			if (action.payload < 0 || state.projectSearchMetadata.totalPages <= action.payload) return;
			state.projectSearchMetadata.currentPageNo = action.payload;
		},
		/**
		 * ! Пам'ятайте, що перед зміною networkStateTs Ви маєте вручну
		 * змінити глобальний об'єкт globalNetworkState.
		 * Такі складнощі продиктовані тим, що цей об'єкт не може бути серіалізований.
		 */
		changeNetworkStateTs: (state) => {
			state.networkStateTs = new Date().valueOf();
		},
		changeEntitySearchParams: (state, action: PayloadAction<Partial<IEntitySearchParams>>) => {
			state.entitySearchParams = { ...state.entitySearchParams, ...action.payload };
		},
	},
});

export const {
	clearProjectSearchParams,
	changeProjectSearchParams,
	changeProjectSearchPageNo,
	changeProjectSearchMetadataCurrent,
	changeProjectSearchMetadataProjects,
	changeNetworkStateTs,
	changeEntitySearchParams,
} = projectSlice.actions;

export default projectSlice.reducer;
