import { skipToken } from '@reduxjs/toolkit/query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { BsArrowLeft, BsExclamationTriangle, BsPencilSquare, BsTrash } from 'react-icons/bs';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { showError, showPromiseConfirm } from '../../alerts';
import { DEFAULTS, DELETION_WARNING, EXIT_CONFIRMATION, TOrigin } from '../../common';
import Asterisk from '../../components/Asterisk';
import CommonSelector from '../../components/CommonSelector';
import DialogFooter from '../../components/DialogFooter';
import { FixedDotSpinner } from '../../components/DotSpinner';
import EntityTypeImage from '../../components/EntityTypeImage';
import FetchError from '../../components/FetchError';
import TextIcon from '../../components/TextIcon';
import CountrySelector from '../../components/controls/CountrySelector';
import DescriptionControl from '../../components/controls/DescriptionControl';
import TextInputsGroup from '../../components/controls/TextInputsGroup';
import WithDictionaries, { IWithDictionaries } from '../../components/hoc/withDictionaries';
import { useDebounce, useFormFields } from '../../hooks';
import { ITextArrayDataOptions, TStringOrNull } from '../../interfaces/common';
import { IEntityObject } from '../../interfaces/entities';
import { getEntityLabelForScheme } from '../../schemeUtils';
import { selectAppStore, useAppSelector } from '../../store';
import { getDataForSave, getEntityLogoSrc } from '../../utils';
import DangerousListItem from '../dangerous/DangerousListItem';
import DangerousZone from '../dangerous/DangerousZone';
import { globalNetworkState } from '../projects/projectSlice';
import { useEditEntityMutation, useEntityQuery } from '../services/entity';
import EntityLogoEditor from './EntityLogoEditor';
import SimilarEntities from './SimilarEntities';

const IN_EDITING_CONFIRM = 'Ви не закінчили редагування однієї з альтернативних назв. Все одно бажаєте вийти?';

const DEFAULT_ENTITY: IEntityObject = {
	entityBase: {
		id: 0,
		entity_type: 14,
		country: 'UKR',
		title: '',
		logo: null,
		description: '',
	},
	alternativeTitles: [],
};
interface IEntityDialogProps extends IWithDictionaries {
	/**
	 * Якщо задана ця функція, то діалог не викликається через роут, а рендериться
	 * батьківськім компонентом. В такому випадку даний компонент при закритті
	 * не використовує свою логіку, а просто викликає дану функцію. Тобто закриття
	 * має здійснюватись батьківськім компонентом.
	 * @param entity Доданий (змінений) об'єкт
	 * @returns
	 */
	directOnClose?: (entity?: IEntityObject) => void;
}
const EntityDialog = ({ entityTypes, directOnClose }: IEntityDialogProps) => {
	const { eId } = useParams();
	const entityId = parseInt(eId || '0');
	const inserting = entityId === 0;
	const navigate = useNavigate();
	const { state: referer } = useLocation();
	const { data: entity, isFetching: entityIsFetching, error: entityError } = useEntityQuery(entityId || skipToken);
	const [initialData, setInitialData] = useState(() => DEFAULT_ENTITY.entityBase);
	const { formFields, createChangeHandler, modified, setFormFields, createSetHandler } = useFormFields(initialData);
	// Тут маються на увазі альтернативні назви
	const [titlesState, setTitlesState] = useState<ITextArrayDataOptions>(() => ({
		modified: false,
		selected: entity?.alternativeTitles || [],
	}));
	const [inEditing, setInEditing] = useState(false);
	const refFirstControl = useRef<HTMLInputElement>(null);
	const { userRights } = useAppSelector(selectAppStore);
	const [updateEntity, updateEntityResult] = useEditEntityMutation();
	const { debouncedValue: debouncedTitle, setTerm: setTitle } = useDebounce(formFields.title, 3000);
	// True, якщо в назві, можливо, присутні альтернативні назви
	const [complexTitle, setComplexTitle] = useState(false);

	const disabled = !userRights?.isEditor || entityIsFetching || updateEntityResult.isLoading;
	const someModified = inserting || modified || titlesState.modified;

	useEffect(() => {
		if (!entity) return;
		const { alternativeTitles, entityBase } = entity;
		setInitialData(entityBase);
		setTitlesState({ modified: false, selected: alternativeTitles });
	}, [entity]);

	useEffect(() => {
		if (disabled) return;
		setTimeout(() => {
			refFirstControl.current?.focus();
		}, 100);
	}, [disabled]);

	const closeDialog = useCallback(() => {
		if (directOnClose) return directOnClose();

		const state = referer as TOrigin;
		if (state?.origin === 'basic') navigate(-1);
		else navigate('..');
	}, [referer, navigate, directOnClose]);

	const handleClose = useCallback(async () => {
		if (someModified && !(await showPromiseConfirm(EXIT_CONFIRMATION))) return;
		if (inEditing && !(await showPromiseConfirm(IN_EDITING_CONFIRM))) return;
		closeDialog();
	}, [someModified, closeDialog, inEditing]);

	const handleBack = useCallback(async () => {
		if (someModified && !(await showPromiseConfirm(EXIT_CONFIRMATION))) return;
		if (inEditing && !(await showPromiseConfirm(IN_EDITING_CONFIRM))) return;
		if (directOnClose) return directOnClose();
		navigate(-1);
	}, [someModified, navigate, directOnClose, inEditing]);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (inEditing) return showError('Завершіть, будь ласка, редагування альтернативної назви');

		const dataToSave = getDataForSave(inserting ? undefined : initialData, formFields, ['id']);
		// console.log({ ...dataToSave, alternativeTiles: titlesState.selected });
		updateEntity({
			...dataToSave,
			alternativeTitles: titlesState.selected,
			method: inserting ? DEFAULTS.httpMethod.post : DEFAULTS.httpMethod.put,
		});
	};

	useEffect(() => {
		if (!updateEntityResult) return;
		const { isError, error, isSuccess, data } = updateEntityResult;
		if (isSuccess) {
			if (!inserting) {
				if (data.action !== 'delete') {
					const { id, entity_type, logo, title } = data.entityBase;
					const node = globalNetworkState?.nodes.get(id);
					if (node)
						globalNetworkState?.nodes.updateOnly({
							id,
							label: getEntityLabelForScheme(title, node.is_init),
							image: getEntityLogoSrc(logo, entity_type),
							imagePadding: logo ? undefined : 7,
							entity_type,
						});
				} else {
					// Видалення об'єкта буде успішним тільки в разі, коли він не має жодних зв'язків,
					// тому тут немає сенсу видаляти зв'язки. Їх просто не буде.
					// Насправді видалення Node тут під питанням. Але хай буде.
					globalNetworkState?.nodes.remove(data.id);
				}
			}
			if (directOnClose) return directOnClose(data);
			/**
			 * Вставка можлива або з діалогу редагування (додавання) зв'язку (цей варіант
			 * буде оброблено попереднім рядком), або з основного вікна (цей варіант
			 * буде оброблено наступним рядком).
			 */
			if (inserting) return navigate(`../${DEFAULTS.routes.entity}/${data.entityBase.id}`, { replace: true });
			// В іншому випадку використовується стандартний оброблювач закриття діалогу.
			const state = referer as TOrigin;
			if (state?.origin === 'search') navigate(-1);
			else closeDialog();
		}
		if (isError) showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
		refFirstControl.current?.focus();
	}, [updateEntityResult]); // eslint-disable-line

	const onCountryChange = (trigram: string) => {
		setFormFields({ ...formFields, country: trigram });
	};

	const onImageChange = (base64Image: TStringOrNull) => {
		setFormFields({ ...formFields, logo: base64Image });
	};

	const onDeleteClick = async () => {
		if (!(await showPromiseConfirm(`Ви впевнені, що хочете видалити об'єкт «${formFields.title}»?`))) return;
		await updateEntity({ id: formFields.id, method: DEFAULTS.httpMethod.delete });
	};

	useEffect(() => {
		if (!inserting) return;
		setTitle(formFields.title);
	}, [formFields.title, inserting, setTitle]);

	useEffect(() => {
		setComplexTitle(/\([^)]+\)/.test(formFields.title));
	}, [formFields.title]);

	if (Number.isNaN(entityId)) return <Navigate to=".." replace />;

	return (
		<Modal
			show
			centered
			size="xl"
			fullscreen="xl-down"
			onHide={handleClose}
			backdropClassName="double-modal-backdrop"
			scrollable
		>
			<Modal.Header closeButton className="align-items-center gap-1">
				{(referer as TOrigin)?.origin === 'search' && (
					<Button variant="secondary" onClick={handleBack} type="button" size="sm">
						<TextIcon Icon={BsArrowLeft}>Назад</TextIcon>
					</Button>
				)}
				<Modal.Title>
					<TextIcon Icon={BsPencilSquare} className="mt-n1 me-1" />
					{inserting ? <>Створення</> : <>Редагування</>} об'єкта
					<EntityTypeImage entityTypeId={formFields.entity_type} className="ms-1" />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="vh-75 vstack">
				{entityError && <FetchError error={entityError} />}
				{(inserting || formFields.id !== 0) && (
					<div className="d-flex gap-2 h-100">
						<div className="flex-grow-1 vstack">
							<Row>
								<ImageContainer className="flex-grow-0">
									<EntityLogoEditor
										src={formFields.logo}
										entityType={formFields.entity_type}
										editable={userRights?.isEditor}
										onImageChange={onImageChange}
									/>
								</ImageContainer>
								<ColAttributes>
									<Form onSubmit={onSubmit} id="fmEntity" className="row g-2">
										<Col xs={12}>
											<Form.Group controlId="eTitle">
												<Form.Label>
													Назва
													<Asterisk />
												</Form.Label>
												<Form.Control
													type="search"
													required
													placeholder="Введіть назву об'єкта"
													maxLength={150}
													value={formFields.title}
													onChange={createChangeHandler('title')}
													autoComplete="off"
													ref={refFirstControl}
													disabled={disabled}
													size="lg"
												/>
												{complexTitle && (
													<Form.Text className="text-danger">
														<BsExclamationTriangle className="mt-n1" /> Можливо, назва містить альтернативний варіант.
														Якщо це так, будь ласка, додайте його окремо у полі нижче.
													</Form.Text>
												)}
											</Form.Group>
										</Col>
										<CommonSelector
											onItemChange={createSetHandler('entity_type', 'number')}
											selectedId={formFields.entity_type}
											allItems={entityTypes.entities}
											label="Тип об'єкта"
											required
											fieldName="entity_type"
											labelName="title"
											valueName="id"
											disabled={disabled}
											containerClassName="col"
										/>
										<CountrySelector
											trigram={formFields.country}
											containerClassName="col"
											onCountryChange={onCountryChange}
											required
											disabled={disabled}
										/>
									</Form>
									<Row className="mt-2">
										<fieldset className="col">
											<legend className="fs-6 mb-0">Альтернативні назви</legend>
											<TextInputsGroup
												onChange={setTitlesState}
												initialData={entity?.alternativeTitles}
												disabled={disabled}
												setInEditing={setInEditing}
												editable={userRights?.isEditor}
											/>
										</fieldset>
									</Row>
								</ColAttributes>
							</Row>
							<DescriptionControl
								controlId="eDescription"
								controlClassName="mt-2 flex-grow-1"
								value={formFields.description ?? ''}
								onChange={createChangeHandler('description')}
								placeholder="Введіть опис об'єкта"
								required
								form="fmEntity"
								className="flex-grow-1"
								disabled={disabled}
								onValueChange={createSetHandler('description')}
							/>
							{!inserting && userRights?.isEditor && (
								<DangerousZone className="mt-2">
									<DangerousListItem onClick={onDeleteClick} title="Видалити об'єкт." description={DELETION_WARNING}>
										<TextIcon Icon={BsTrash}>Видалити</TextIcon>
									</DangerousListItem>
								</DangerousZone>
							)}
						</div>
						{inserting && <SimilarEntities term={debouncedTitle} className="w-25 overflow-auto" />}
					</div>
				)}
				{entityIsFetching && <FixedDotSpinner>Завантаження інформації про об'єкт</FixedDotSpinner>}
			</Modal.Body>
			<DialogFooter
				formId="fmEntity"
				entityId={inserting ? undefined : entityId}
				disabled={!someModified || updateEntityResult.isLoading}
				isLoading={updateEntityResult.isLoading}
				onClose={handleClose}
			/>
		</Modal>
	);
};

export default WithDictionaries(EntityDialog);

const ImageContainer = styled(Col)`
	/* flex-basis: 20%; */
	flex-basis: 227px;
`;

const ColAttributes = styled(Col)`
	.form-label {
		margin-bottom: 0;
	}
`;
