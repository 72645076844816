import classNames from 'classnames';
import React from 'react';

interface IAsteriskProps extends React.HTMLAttributes<HTMLDivElement> {
	position?: 'start' | 'end';
	colorClassName?: string;
}
const Asterisk = ({ position = 'end', colorClassName = 'text-danger', className }: IAsteriskProps) => (
	<span className={classNames(position === 'end' ? 'ms-1' : 'me-1', colorClassName, className)}>*</span>
);

interface IAsteriskTextProps extends React.AllHTMLAttributes<HTMLDivElement> {
	text?: string;
	muted?: boolean;
}
export const AsteriskText = ({ muted = true, className, text = 'Поле має бути заповнене' }: IAsteriskTextProps) => (
	<div className={className}>
		<Asterisk position="start" />
		<span className={classNames('text-small', muted && 'text-secondary')}>{text}</span>
	</div>
);

export default Asterisk;
