import { skipToken } from '@reduxjs/toolkit/query';
import React, { useEffect, useRef } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { BsArrowLeft, BsLink45Deg, BsPencilSquare } from 'react-icons/bs';
import { Link, Navigate, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DEFAULTS, TOrigin } from '../../common';
import { FixedDotSpinner } from '../../components/DotSpinner';
import EntityTypeImage from '../../components/EntityTypeImage';
import FetchError from '../../components/FetchError';
import NatoCountryFlag from '../../components/NatoCountryFlag';
import TextIcon from '../../components/TextIcon';
import DescriptionText from '../../components/controls/DescriptionText';
import WithDictionaries, { IWithDictionaries } from '../../components/hoc/withDictionaries';
import { TProjectContext } from '../../interfaces/projects';
import { moveControlToScreenCenter, scrollContainerToTop } from '../../scrolls';
import { selectAppStore, useAppSelector } from '../../store';
import { useEntityQuery } from '../services/entity';
import EntityLinks from './EntityLinks';
import EntityLogoEditor from './EntityLogoEditor';

interface IFullEntityDialogProps extends IWithDictionaries, React.AllHTMLAttributes<HTMLDivElement> {}
const FullEntityDialog = ({ entityTypes }: IFullEntityDialogProps) => {
	const { eId } = useParams();
	const entityId = parseInt(eId || '-');
	const { userProjectRights, projectId } = useOutletContext<TProjectContext>();
	const { userRights } = useAppSelector(selectAppStore);
	const { state: referer } = useLocation();
	const navigate = useNavigate();
	const { data: entity, isFetching: entityIsFetching, error: entityError } = useEntityQuery(entityId || skipToken);
	const refBody = useRef<HTMLDivElement>(null);

	useEffect(() => {
		scrollContainerToTop(refBody.current, 'smooth');
	}, [entityId]);

	const closeDialog = () => {
		// if ((referer as TOrigin)?.origin === 'basic') navigate(-1);
		// else navigate('..');
		navigate('..');
	};

	const handleBack = () => {
		navigate(-1);
		const state = referer as TOrigin;
		if (state?.controlId)
			setTimeout(() => {
				if (state?.controlId) moveControlToScreenCenter(document.getElementById(state.controlId));
			}, 200);
	};

	if (Number.isNaN(entityId)) return <Navigate to=".." replace />;

	const { entityBase, alternativeTitles = [] } = entity ?? {};
	return (
		<Modal show centered size="xl" fullscreen="xl-down" onHide={closeDialog} scrollable>
			<Modal.Header closeButton className="align-items-center gap-1">
				{(referer as TOrigin)?.origin === 'search' && (
					<Button variant="secondary" onClick={handleBack} type="button" size="sm" className="text-nowrap">
						<TextIcon Icon={BsArrowLeft}>Назад</TextIcon>
					</Button>
				)}
				<Modal.Title className="text-truncate">
					<EntityTypeImage entityTypeId={entityBase?.entity_type} className="mx-2" lg />
					{entityBase?.title || ''}
				</Modal.Title>
				<span className="text-nowrap fs-4">
					{userRights?.isEditor && entityBase && (
						<Link
							to={`../${DEFAULTS.routes.entityEdit}/${entityId}`}
							state={DEFAULTS.origins.search}
							title="Редагувати об'єкт"
						>
							<BsPencilSquare />
						</Link>
					)}
					{userProjectRights?.is_editor && entityBase && (
						<Link
							to={`../${DEFAULTS.routes.relationship}/0`}
							state={{ entityId, ...DEFAULTS.origins.search }}
							className="ms-1"
							title="Створити новий зв'язок"
						>
							<BsLink45Deg />
						</Link>
					)}
				</span>
			</Modal.Header>
			<Modal.Body className="vh-75 vstack position-relative pt-0" ref={refBody}>
				{entityError && <FetchError error={entityError} />}
				{entityBase && !entityError && (
					<Row className="mt-3">
						<Col xs={3}>
							<EntityLogoEditor
								src={entityBase.logo}
								entityType={entityBase.entity_type}
								editable={false}
								className="position-sticky top-0"
							/>
						</Col>
						<Col>
							<div className="vstack gap-2">
								<h3 className="mb-0 hstack gap-1 align-items-start justify-content-between">
									<span>{entityBase.title}</span>
									<NatoCountryFlag trigram={entityBase.country} />
								</h3>
								<div className="mt-n2">
									<EntityTypeImage entityTypeId={entityBase.entity_type} className="me-2" lg />
									{entityTypes.entities[entityBase.entity_type]?.title}
								</div>
								{alternativeTitles.length !== 0 && (
									<div className="hstack gap-1 flex-wrap">
										<span>Альтернативні назви:</span>
										{alternativeTitles.map((t) => (
											<span className="badge bg-slight-darker text-truncate" key={t}>
												{t}
											</span>
										))}
									</div>
								)}
								<DescriptionText description={entityBase.description ?? ''} maxHeight="200px" />
							</div>
						</Col>
					</Row>
				)}
				{!entityError && (
					<EntityLinks
						projectId={projectId}
						entityId={entityId}
						isProjectEditor={userProjectRights?.is_editor}
						entity={entity?.entityBase}
						userRights={userRights}
					/>
				)}
				{entityIsFetching && <FixedDotSpinner>Завантаження інформації про об'єкт</FixedDotSpinner>}
			</Modal.Body>
		</Modal>
	);
};

export default WithDictionaries(FullEntityDialog);
