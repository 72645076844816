import classNames from 'classnames';
import React from 'react';
import { BsLink45Deg, BsPeople } from 'react-icons/bs';
import TextIcon from '../../components/TextIcon';

interface IProjectContentStatisticProps extends React.AllHTMLAttributes<HTMLDivElement> {
	entitiesCount?: number;
	relationshipsCount?: number;
	withLabels?: boolean;
}
const ProjectContentStatistic = ({
	entitiesCount,
	relationshipsCount,
	className,
	withLabels,
}: IProjectContentStatisticProps) => {
	return (
		<div className={classNames('text-nowrap hstack gap-2', className)}>
			<TextIcon Icon={BsPeople} size="lg" className="mt-n1" title="Кількість об'єктів">
				{withLabels && <>Об'єкти: </>} {entitiesCount ?? '-'}
			</TextIcon>
			<TextIcon Icon={BsLink45Deg} size="lg" title="Кількість зв'язків">
				{withLabels && <>Зв'язки: </>} {relationshipsCount ?? '-'}
			</TextIcon>
		</div>
	);
};

export default ProjectContentStatistic;
