import React from 'react';
import { useOutletContext } from 'react-router-dom';
import WithDictionaries, { IWithDictionaries } from '../../components/hoc/withDictionaries';
import WithUnits, { IWithUnits } from '../../components/hoc/withUnits';
import { IUserRights } from '../../interfaces/dicts';
import { IEntityBase } from '../../interfaces/entities';
import { TProjectContext } from '../../interfaces/projects';
import { IEntityLinksByType } from '../services/entity';
import RelationshipsOfType from './RelationshipsOfType';

interface IRelationshipsByTypeProps extends IWithDictionaries, IWithUnits, React.AllHTMLAttributes<HTMLDivElement> {
	entity: IEntityBase;
	isProjectEditor?: boolean;
	links: IEntityLinksByType;
	userRights?: IUserRights;
}
const RelationshipsByType = ({
	links,
	isProjectEditor,
	relationshipTypes,
	units,
	userRights,
}: IRelationshipsByTypeProps) => {
	const { projectRelationshipsIds, projectEntitiesIds } = useOutletContext<TProjectContext>();

	return (
		<div className="vstack gap-3">
			{links.ids.map((typeId) => (
				<RelationshipsOfType
					key={typeId}
					isProjectEditor={isProjectEditor}
					linksIds={links.entities[typeId]}
					relationships={links.relationships}
					relationshipType={relationshipTypes.entities[typeId]}
					units={units.entities}
					userRights={userRights}
					projectRelationshipsIds={projectRelationshipsIds}
					entitiesInProjectIds={projectEntitiesIds}
				/>
			))}
		</div>
	);
};

export default WithUnits(WithDictionaries(RelationshipsByType));
