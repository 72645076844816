import React from 'react';
import { BsCircleFill } from 'react-icons/bs';
import styled from 'styled-components';
import { IdType } from 'vis-network/standalone';
import { IDictionary } from '../../interfaces/common';
import { IRelationshipTypeDic, IUnitDic, IUserRights } from '../../interfaces/dicts';
import { GridAutoFill } from '../../styledComponents';
import { IEntityLink } from '../services/entity';
import RelationshipCard from './RelationshipCard';

interface IRelationshipsOfTypeProps extends React.AllHTMLAttributes<HTMLDivElement> {
	isProjectEditor?: boolean;
	linksIds?: string[];
	relationships: Record<string, IEntityLink>;
	relationshipType?: IRelationshipTypeDic;
	units: IDictionary<number, IUnitDic>;
	userRights?: IUserRights;
	projectRelationshipsIds: Set<IdType>;
	entitiesInProjectIds?: Set<IdType>;
}
const RelationshipsOfType = ({
	linksIds,
	relationships,
	isProjectEditor,
	relationshipType,
	units,
	userRights,
	projectRelationshipsIds,
	entitiesInProjectIds,
}: IRelationshipsOfTypeProps) => {
	if (!linksIds || !relationshipType) return null;

	return (
		<Section>
			<GridAutoFill $baseSize="360px" className="align-items-start">
				{linksIds.map((linkId, index) => {
					const link = relationships[linkId];
					if (!link) return null;
					return (
						<React.Fragment key={linkId}>
							{(index === 0 ||
								(link.direct_order !== relationships[linksIds[index - 1]]?.direct_order &&
									relationshipType.directional)) && (
								<h5 className="text-center position-sticky mb-0 top-0 bg-default z-2 grid-whole-row">
									{link.direct_order ? relationshipType.title : relationshipType.backTitle}
								</h5>
							)}
							<RelationshipCard
								link={link}
								directional={relationshipType.directional}
								isProjectEditor={isProjectEditor}
								unitTitle={units[link.creator_unit_id]?.code}
								userRights={userRights}
								inCurrentProject={projectRelationshipsIds.has(linkId)}
								entityInProject={entitiesInProjectIds?.has(link.complementary_entity_id)}
							/>
						</React.Fragment>
					);
				})}
			</GridAutoFill>
			<div className="border-bottom mt-4 position-relative border-info section-separator opacity-50">
				<BsCircleFill className="position-absolute top-0 start-50 translate-middle text-info fs-5" />
			</div>
		</Section>
	);
};

export default RelationshipsOfType;

const Section = styled.section`
	&:last-child {
		.section-separator {
			display: none;
		}
	}
`;
