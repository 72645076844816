import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Spinner, { SpinnerProps } from 'react-bootstrap/Spinner';

interface IDotSpinnerProps extends Omit<SpinnerProps, 'animation'> {}

const SHOW_SPINNER_WAIT_INTERVAL = 300;

const DotSpinner = ({ children = 'Пошук', className, variant, ...props }: IDotSpinnerProps) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		const tm = window.setTimeout(setShow, SHOW_SPINNER_WAIT_INTERVAL, true);
		return () => clearTimeout(tm);
	}, []);

	if (!show) return <></>;
	return (
		<div className={classNames('d-flex justify-content-center align-items-center', className)}>
			<div className="me-1">{children}</div>
			<Spinner {...props} variant={variant} animation="grow" />
		</div>
	);
};

export default DotSpinner;

interface IFixedDotSpinnerProps extends IDotSpinnerProps {
	contrast?: boolean;
}
export const FixedDotSpinner = ({ contrast = true, className, ...rest }: IFixedDotSpinnerProps) => (
	<DotSpinner
		{...rest}
		className={classNames(
			'position-fixed start-50 top-50 px-3 py-2 rounded translate-middle',
			contrast ? 'dark-glass' : 'white-glass',
			className
		)}
	/>
);
