import classNames from 'classnames';
import React from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { DEFAULTS } from '../../common';
import InProjectEntityPatch from '../../components/InProjectEntityPatch';
import NatoCountryFlag from '../../components/NatoCountryFlag';
import WithDictionaries, { IWithDictionaries } from '../../components/hoc/withDictionaries';
import { EntityLogo } from '../../styledComponents';
import { IEntitySearch } from '../services/entity';
import EntityTitleWithType from './EntityTitleWithType';

interface IEntityCardProps extends IWithDictionaries, React.AllHTMLAttributes<HTMLDivElement> {
	entity: IEntitySearch;
	inProject: boolean;
	simple?: boolean;
}
const EntityCard = ({ entity, inProject, entityTypes, className, simple = false }: IEntityCardProps) => {
	const { id, title, entity_type, country, alternative_titles } = entity;

	return (
		<div
			className={classNames(
				className,
				'position-relative hstack gap-2 align-self-start rounded p-1 border shadow-sm lh-sm min-width-0',
				inProject ? 'text-bg-warning' : 'bg-secondary-subtle'
			)}
			// style={{ opacity: sml + 0.3 }}
		>
			<EntityLogo
				entity={entity}
				className="rounded flex-shrink-0 align-self-start"
				$size={simple ? '36px' : undefined}
			/>
			<div className={classNames('d-flex flex-column min-width-0', !simple && 'pe-3')}>
				<Link
					to={`../${DEFAULTS.routes.entity}/${id}`}
					className={classNames('h6 mb-0 stretched-link link-body-emphasis', simple && 'text-truncate')}
					state={DEFAULTS.origins.search}
				>
					<EntityTitleWithType title={title} typeId={entity_type} />
				</Link>
				<span className="text-truncate text-small text-secondary">{entityTypes.entities[entity_type]?.title}</span>
				{/* <span title="Релевантність" className="position-absolute bottom-0 end-0 badge me-1 mb-1">
					{(sml * 100).toFixed()} %
				</span> */}
				{alternative_titles.length !== 0 && (
					<div className="text-muted hstack gap-1 flex-wrap mt-1">
						{alternative_titles.map((t) => (
							<span className="badge bg-slight-darker text-truncate" key={t}>
								{t}
							</span>
						))}
					</div>
				)}
			</div>
			{!simple && (
				<div className="d-flex flex-column ms-auto flex-shrink-0 align-self-stretch justify-content-between z-1">
					<NatoCountryFlag trigram={country} isSquare />
					{inProject && <InProjectEntityPatch entityId={entity.id} />}
					<Link
						to={`../${DEFAULTS.routes.entityEdit}/${id}`}
						state={DEFAULTS.origins.search}
						className="link-secondary"
						title="Редагувати"
					>
						<BsPencilSquare />
					</Link>
				</div>
			)}
		</div>
	);
};

export default WithDictionaries(EntityCard);
