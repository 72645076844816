import classNames from 'classnames';
import WithDictionaries, { IWithDictionaries } from '../../components/hoc/withDictionaries';
import { IEntityBase } from '../../interfaces/entities';
import ProjectEntityItem from './projectEntityItem';

interface IProjectEntityListProps extends IWithDictionaries, React.AllHTMLAttributes<HTMLDivElement> {
	entities: IEntityBase[];
	withActions?: boolean;
}
const ProjectEntityList = ({
	countries,
	entities,
	className,
	children,
	withActions = false,
	onClick,
}: IProjectEntityListProps) => {
	return (
		<div className={classNames(className, 'd-flex flex-wrap gap-1')} onClick={onClick}>
			{entities.map((entity) => (
				<ProjectEntityItem
					key={entity.id}
					entity={entity}
					countryDigram={countries.entities[entity.country]?.digram}
					withActions={withActions}
				/>
			))}
			{children}
		</div>
	);
};

export default WithDictionaries(ProjectEntityList);
