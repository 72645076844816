import React from 'react';
import { BsCircleFill, BsCrosshair } from 'react-icons/bs';
import styled from 'styled-components';
import ArrowDirection from '../../components/ArrowDirection';
import PrintableHashLink from '../../components/PrintableHashLink';
import DescriptionText from '../../components/controls/DescriptionText';
import WithDictionaries, { IWithDictionaries } from '../../components/hoc/withDictionaries';
import { IDictionary } from '../../interfaces/common';
import { IRelationshipListItem } from '../../interfaces/relationship';
import { getRelationshipImageSrc } from '../../utils';
import EntityTitleWithType from '../entities/EntityTitleWithType';
import { ISummaryEntity, ISummaryEntityRelationship } from '../services/project';

// const RELATIONSHIP_CLASS_NAME = 'text-center mt-3 border-bottom border-2';
const RELATIONSHIP_CLASS_NAME = 'text-center mt-3';

interface IEntitySummaryRelationshipListProps extends IWithDictionaries, React.AllHTMLAttributes<HTMLDivElement> {
	entityId: number;
	entities: IDictionary<number, ISummaryEntity>;
	relationships: IDictionary<string, IRelationshipListItem & { images: string[] }>;
	entityRelationships: ISummaryEntityRelationship[];
}
const EntitySummaryRelationshipList = ({
	entityTypes: { entities: entityTypes },
	relationshipTypes: { entities: relationshipTypes },
	entityId,
	entities,
	relationships,
	entityRelationships,
}: IEntitySummaryRelationshipListProps) => {
	return (
		<div>
			{entityRelationships.map((r, index) => {
				const link = relationships[r.relationshipId];
				const priorLink = index !== 0 ? relationships[entityRelationships[index - 1]?.relationshipId] : null;
				if (!link) return <div className="text-danger">Can't find relationship {r.relationshipId}</div>;
				const complementaryEntity = entities[r.directOrder ? link.entity_id_2 : link.entity_id_1];
				if (!complementaryEntity) return <div className="text-danger">Can't find entity {entityId}</div>;
				const relationshipType = relationshipTypes[link.relationship_type_id];
				return (
					<React.Fragment key={r.relationshipId}>
						{(index === 0 || link.relationship_type_id !== priorLink?.relationship_type_id) && (
							<>
								<div className="border-bottom mt-3 position-relative border-info section-separator opacity-50">
									<BsCircleFill className="position-absolute top-0 start-50 translate-middle text-info fs-5" />
								</div>
								<h4 className={RELATIONSHIP_CLASS_NAME}>
									{r.directOrder ? relationshipType?.title : relationshipType?.backTitle}
								</h4>
							</>
						)}
						{index !== 0 &&
							relationshipType?.directional &&
							link.relationship_type_id === priorLink?.relationship_type_id &&
							r.directOrder !== entityRelationships[index - 1].directOrder && (
								<h4 className={RELATIONSHIP_CLASS_NAME}>
									{r.directOrder ? relationshipType?.title : relationshipType?.backTitle}
								</h4>
							)}
						<RelationshipSection>
							<h5 className="text-center">
								<ArrowDirection
									directional={relationshipType?.directional}
									direct={r.directOrder}
									className="me-1 mt-n1"
								/>
								<PrintableHashLink targetId={`card_entity_${complementaryEntity.id}`} className="text-decoration-none">
									{complementaryEntity.is_init && <BsCrosshair className="me-1 mt-n1" />}
									<EntityTitleWithType typeId={complementaryEntity.entity_type} title={complementaryEntity.title} />
								</PrintableHashLink>
								<span className="ms-2 text-muted text-small">
									({entityTypes[complementaryEntity.entity_type]?.title})
								</span>
							</h5>
							<DescriptionText description={link.description || undefined} />
							{link.images.length !== 0 && (
								<div className="vstack gap-3 my-3 align-items-center">
									{link.images.map((src) => (
										<AttachedImage key={src} src={getRelationshipImageSrc(src)} className="img-thumbnail" />
									))}
								</div>
							)}
						</RelationshipSection>
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default WithDictionaries(EntitySummaryRelationshipList);

const RelationshipSection = styled.section`
	& + & {
		margin-top: 0.5rem;
	}
`;

const AttachedImage = styled.img`
	max-width: 62%;
`;
