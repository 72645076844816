// import { promiseTimeOut } from './utils';

import { promiseTimeout } from './common';

/**
 * `scrollWindowToTop` is a function that scrolls the window to the top of the page
 * @param {'auto' | 'smooth'} [behavior=auto] - 'auto' | 'smooth' = 'auto'
 */
export const scrollWindowToTop = (behavior: 'auto' | 'smooth' = 'smooth') => window.scrollTo({ top: 0, behavior });

// export const deferredScroll = (behavior: 'auto' | 'smooth' = 'smooth', timeout = 100) =>
// 	setTimeout(() => {
// 		scrollWindowToTop(behavior);
// 	}, timeout);

export const scrollContainerToTop = (container: Element | null, behavior: ScrollBehavior = 'auto') =>
	container?.scrollTo({ top: 0, behavior });

export const scrollContainerToBottom = (container: Element | null, behavior: ScrollBehavior = 'auto') =>
	container?.scrollTo({ top: 10000, behavior });

// export const scrollWindowToBottom = (behavior: 'auto' | 'smooth' = 'auto') =>
// 	window.scrollTo({
// 		top: 10000,
// 		behavior,
// 	});

export const moveControlToScreenPosition = (
	control?: Element | null,
	block: ScrollLogicalPosition = 'center',
	highlight: boolean = false
) => {
	if (!control) return;
	control.scrollIntoView({ behavior: 'smooth', block });
	if (!highlight) return;
	highlightControl(control);
};

export const moveControlToScreenCenter = (control?: Element | null, highlight: boolean = false) =>
	moveControlToScreenPosition(control, 'center', highlight);

export const highlightControl = async (control?: Element | null, timeout?: number) => {
	if (!control) return;
	if (timeout) await promiseTimeout(timeout);
	control.classList.add('animation-highlighted');
	await promiseTimeout(500);
	control.classList.remove('animation-highlighted');
};
