import classNames from 'classnames';
import { useMemo, useRef } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { BsImage, BsTrash } from 'react-icons/bs';
import styled from 'styled-components';
import { showError } from '../../alerts';
import TextIcon from '../../components/TextIcon';
import { TStringOrNull } from '../../interfaces/common';
import { getEntityLogoSrc, loadFileToImageControl } from '../../utils';

interface IEntityLogoEditorProps {
	src: TStringOrNull;
	entityType: number;
	editable?: boolean;
	onImageChange?: (base64Image: TStringOrNull) => void;
	className?: string;
}
const EntityLogoEditor = ({ className, src, entityType, editable, onImageChange }: IEntityLogoEditorProps) => {
	const refFile = useRef<HTMLInputElement>(null);

	const showSelectedImage = async () => {
		if (!onImageChange) return;
		try {
			const src = await loadFileToImageControl(refFile.current);
			if (src) onImageChange(src);
		} catch (error) {
			showError(error as any);
		}
	};

	const onChange = () => refFile.current?.click();

	const onDelete = () => onImageChange && onImageChange(null);

	const realSrc = useMemo(() => getEntityLogoSrc(src, entityType), [src, entityType]);

	return (
		<LogoImage className={classNames('w-100 rounded border position-relative', className)} $src={realSrc}>
			{editable && (
				<ButtonGroup size="sm" className="position-absolute bottom-0 start-50 mb-2 translate-middle-x">
					<Button variant={src ? 'secondary' : 'primary'} onClick={onChange} title="Змінити">
						<TextIcon Icon={BsImage}>
							<span className="d-none d-lg-inline">Змінити</span>
						</TextIcon>
					</Button>
					<Button variant="danger" disabled={!src} onClick={onDelete} title="Видалити">
						<TextIcon Icon={BsTrash}>
							<span className="d-none d-lg-inline">Видалити</span>
						</TextIcon>
					</Button>
				</ButtonGroup>
			)}
			<input
				ref={refFile}
				type="file"
				id="fileUpload"
				name="fileUpload"
				accept=".png, .jpg, .jpeg, .svg, .webp"
				autoComplete="off"
				className="d-none"
				onChange={showSelectedImage}
			/>
		</LogoImage>
	);
};

export default EntityLogoEditor;

interface ILogoImage {
	$src: string;
}
const LogoImage = styled.div<ILogoImage>`
	padding-bottom: 100%;
	background: ${({ $src }) => `url(${$src}) center / cover no-repeat`};
`;
