import React from 'react';
import { BsArrowLeftCircle, BsArrowLeftRight, BsArrowRightCircle } from 'react-icons/bs';
import TextIcon from './TextIcon';

interface IArrowDirectionProps extends React.AllHTMLAttributes<HTMLDivElement> {
	directional?: boolean;
	direct?: boolean;
}
const ArrowDirection = ({ className, directional, direct }: IArrowDirectionProps) => {
	return (
		<TextIcon
			Icon={directional ? (direct ? BsArrowRightCircle : BsArrowLeftCircle) : BsArrowLeftRight}
			className={className}
		/>
	);
};

export default ArrowDirection;
