import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULTS, permissionExists, TStringWithUndefined } from '../../common';
import { storage, TColorTheme, updateThemeInPage } from '../../interfaces/common';
import { IUserRights } from '../../interfaces/dicts';

export interface IAppSlice {
	userPermissions: string[];
	hasAccess: boolean;
	isAdmin: boolean;
	// toasts: IToast[];
	colorTheme: TColorTheme;
	accessToken: TStringWithUndefined;
	userRights?: IUserRights;
}
const initialState: IAppSlice = {
	userPermissions: [],
	hasAccess: false,
	isAdmin: false,
	// toasts: [],
	colorTheme: (storage.get('theme') || 'light') as TColorTheme,
	accessToken: undefined,
};
export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		changeAccessToken: (state, action: PayloadAction<TStringWithUndefined>) => {
			state.accessToken = action.payload;
		},
		changePermissions: (state, action: PayloadAction<string[] | undefined>) => {
			state.userPermissions = action.payload ? [...action.payload] : [];
			state.hasAccess = permissionExists(DEFAULTS.accessPermissionName, action.payload);
			state.isAdmin = permissionExists(DEFAULTS.adminPermissionName, action.payload);
		},
		// addToast: (state, action: PayloadAction<Omit<IToast, 'id'>>) => {
		// 	state.toasts.push({ ...action.payload, id: new Date().valueOf() });
		// },
		// removeToast: (state, action: PayloadAction<number>) => {
		// 	state.toasts = state.toasts.filter(({ id }) => id !== action.payload);
		// },
		changeColorTheme: (state, action: PayloadAction<TColorTheme>) => {
			state.colorTheme = action.payload;
			storage.set({ theme: action.payload });
			updateThemeInPage(action.payload);
		},
		changeUserRights: (state, action: PayloadAction<IUserRights>) => {
			state.userRights = action.payload;
		},
	},
});

export const { changePermissions, changeColorTheme, changeAccessToken, changeUserRights } = appSlice.actions;

export default appSlice.reducer;
