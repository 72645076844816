import { skipToken } from '@reduxjs/toolkit/query';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { BsPatchCheckFill, BsPlusCircle } from 'react-icons/bs';
import Select, { ActionMeta, OptionProps, SingleValue, SingleValueProps, components } from 'react-select';
import { IdType } from 'vis-network/standalone';
import { DEFAULTS } from '../../common';
import EntityDialog from '../../features/entities/EntityDialog';
import EntityTitleWithType from '../../features/entities/EntityTitleWithType';
import { useEntityQuery, useEntitySearchQuery } from '../../features/services/entity';
import { useDebounce } from '../../hooks';
import { TStringOrNull } from '../../interfaces/common';
import { IEntityBase, IEntityObject } from '../../interfaces/entities';
import { EntityLogo } from '../../styledComponents';
import Asterisk from '../Asterisk';
import NatoCountryFlag from '../NatoCountryFlag';

interface IEntitySelectOption {
	label: string | undefined;
	value: number;
	logo: TStringOrNull;
	entity_type: number;
	inProject: boolean;
	country: string;
}

interface IEntitySelectorProps extends React.AllHTMLAttributes<HTMLDivElement> {
	initialEntityId: number;
	fieldName: string;
	onEntityChange?: (newEntityId: number) => void;
	onEntitySelect?: (entity: IEntityBase) => void;
	onEntity?: (fieldName: string, entity: IEntityBase | null) => void;
	focusOnShow?: boolean;
	projectEntitiesIds: Set<IdType>;
	withAddButton?: boolean;
	clearAfterSelect?: boolean;
}
const EntitySelector = ({
	initialEntityId,
	title,
	className,
	fieldName,
	disabled,
	onEntityChange,
	onEntitySelect,
	focusOnShow,
	projectEntitiesIds,
	withAddButton = false,
	required = true,
	clearAfterSelect,
	onEntity,
}: IEntitySelectorProps) => {
	const [entity, setEntity] = useState<IEntityBase | null>(() => null);
	const [showInsertDialog, setShowInsertDialog] = useState(false);
	const { debouncedValue, setTerm } = useDebounce('', 500);
	const { data: entityData, isFetching: entityIsFetching } = useEntityQuery(initialEntityId || skipToken, {
		skip: initialEntityId === entity?.id,
	});
	const { data: searchData, isFetching: searchIsFetching } = useEntitySearchQuery(
		{ term: debouncedValue, pId: '', count: '36' },
		{ skip: debouncedValue.length < 3 }
	);
	const [entityValue, setEntityValue] = useState<IEntitySelectOption | null>();
	const [options, setOptions] = useState<IEntitySelectOption[]>([]);
	const refFirstControl = useRef<any>(null);

	useEffect(() => {
		if (!onEntity) return;
		onEntity(fieldName, entity);
	}, [entity, fieldName, onEntity]);

	const onInputChange = useCallback(
		(term: string) => {
			setTerm(term);
		},
		[setTerm]
	);

	const onSingleChange = useCallback(
		(value: SingleValue<IEntitySelectOption>, actionMeta: ActionMeta<IEntitySelectOption>) => {
			const newEntity = searchData?.find((d) => d.id === value?.value);
			if (newEntity) {
				setEntity(!clearAfterSelect ? newEntity : null);
				if (onEntitySelect) onEntitySelect({ ...newEntity });
			}
			if (value?.value && onEntityChange) onEntityChange(value.value);
		},
		[onEntityChange, onEntitySelect, searchData, clearAfterSelect]
	);

	useEffect(() => {
		if (initialEntityId === entity?.id) return;
		setEntity(
			initialEntityId === 0 ? null : initialEntityId === entityData?.entityBase.id ? entityData.entityBase : null
		);
	}, [initialEntityId]); // eslint-disable-line

	useEffect(() => {
		if (!entityData) return;
		setEntity(entityData.entityBase);
	}, [entityData]);

	useEffect(() => {
		if (entity && entity.id === entityValue?.value) return;
		setEntityValue(
			entity
				? {
						label: entity.title,
						value: entity.id,
						logo: entity.logo,
						entity_type: entity.entity_type,
						inProject: projectEntitiesIds.has(entity.id),
						country: entity.country,
				  }
				: null
		);
	}, [entity, entityValue?.value]); // eslint-disable-line

	useEffect(() => {
		setOptions(
			(searchData || [])?.map(
				({ id, title, logo, entity_type, country }) =>
					({
						label: title,
						value: id,
						logo,
						entity_type: entity_type,
						inProject: projectEntitiesIds.has(id),
						country,
					} satisfies IEntitySelectOption)
			)
		);
	}, [searchData]); // eslint-disable-line

	useEffect(() => {
		setTimeout(() => {
			if (focusOnShow) refFirstControl.current.focus();
		}, 200);
	}, [focusOnShow]);

	const onEntityInserted = useCallback(
		(newEntity?: IEntityObject) => {
			setShowInsertDialog(false);
			if (!newEntity) return;
			setEntity(!clearAfterSelect ? newEntity.entityBase : null);
			if (onEntityChange) onEntityChange(newEntity.entityBase.id);
			if (onEntitySelect) onEntitySelect({ ...newEntity.entityBase });
		},
		[onEntityChange, onEntitySelect, clearAfterSelect]
	);

	const onInsertEntityClick = useCallback(() => setShowInsertDialog(true), []);

	// if (fieldName === 'entity_id_1') console.log(entity);

	const isFetching = entityIsFetching || searchIsFetching;
	return (
		<>
			<Form.Group controlId={`r_${fieldName}`} className={className}>
				{title && (
					<Form.Label>
						{title}
						<Asterisk />
					</Form.Label>
				)}
				<div className="hstack">
					<Select
						classNamePrefix="themed-select"
						isSearchable
						options={options}
						value={entityValue}
						onChange={onSingleChange as any}
						onInputChange={onInputChange}
						placeholder="Назва об'єкта..."
						name={`select_${fieldName}`}
						components={{ SingleValue: SingleElement, Option: EntityOption }}
						noOptionsMessage={noMatch}
						// menuIsOpen
						isLoading={isFetching}
						className={classNames('flex-grow-1', withAddButton && 'with-button')}
						isDisabled={disabled}
						ref={refFirstControl}
						required={required}
						filterOption={filterOption}
					/>
					{withAddButton && (
						<Button
							variant="outline-secondary"
							className="rounded-start-0"
							title="Додати новий об'єкт"
							tabIndex={-1}
							onClick={onInsertEntityClick}
						>
							<BsPlusCircle className="icon-lg mt-n1" />
						</Button>
					)}
				</div>
			</Form.Group>
			{showInsertDialog && <EntityDialog directOnClose={onEntityInserted} />}
		</>
	);
};

export default EntitySelector;

const SingleElement = ({ data, ...props }: SingleValueProps<IEntitySelectOption>) => {
	return (
		<components.SingleValue data={data} {...props} className="d-flex gap-1 align-items-center">
			<EntitySingleValue {...data} title={data.label || ''} withCountry />
		</components.SingleValue>
	);
};

const EntityOption = ({ children, data, ...props }: OptionProps<IEntitySelectOption, false>) => (
	<components.Option data={data} {...props} className="d-flex gap-1 align-items-center">
		<EntitySingleValue {...data} title={data.label || "Об'єкт не обрано"} withCountry />
	</components.Option>
);

interface IEntitySingleValueProps extends Pick<IEntityBase, 'entity_type' | 'logo' | 'title' | 'country'> {
	inProject: boolean;
	withCountry?: boolean;
}
const EntitySingleValue = (props: IEntitySingleValueProps) => (
	<>
		<EntityLogo entity={props} $size="32px" className="rounded flex-shrink-0 align-self-start" />
		<EntityTitleWithType title={props.title} typeId={props.entity_type} className="me-auto" />
		{props.inProject && <BsPatchCheckFill className="in-project-path flex-shrink-0" />}
		{props.withCountry && <NatoCountryFlag trigram={props.country} isSquare className="flex-shrink-0" />}
	</>
);

// Ми вже вибрали потрібні нам об'єкти, тому показуємо тут всі
const filterOption = () => true;
const noMatch = () => DEFAULTS.noMatch;
