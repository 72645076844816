import React, { useEffect } from 'react';
import { showError } from '../../alerts';
import { DEFAULTS, TLinkAction } from '../../common';
import DotSpinner from '../../components/DotSpinner';
import FetchError from '../../components/FetchError';
import { IUserRights } from '../../interfaces/dicts';
import { IEntityBase } from '../../interfaces/entities';
import { TEntityForNode, removeEdge, upsertEdge } from '../../schemeUtils';
import { useAppDispatch } from '../../store';
import { changeNetworkStateTs } from '../projects/projectSlice';
import RelationshipsByTypes from '../relationships/RelationshipsByTypes';
import { useEntityLinksQuery } from '../services/entity';
import { useRelationshipActionMutation } from '../services/project';

interface IEntityLinksProps extends React.AllHTMLAttributes<HTMLDivElement> {
	entityId: number;
	entity?: IEntityBase;
	projectId: number;
	isProjectEditor?: boolean;
	userRights?: IUserRights;
}
const EntityLinks = ({ entityId, isProjectEditor, entity, projectId, userRights }: IEntityLinksProps) => {
	const { data: links, error, isFetching } = useEntityLinksQuery({ eId: entityId, pId: projectId });
	const [relationshipAction, relationshipActionResult] = useRelationshipActionMutation();
	const dispatch = useAppDispatch();

	const onButtonActionClick = async (event: React.MouseEvent<HTMLDivElement>) => {
		const dataset = ((event.target as HTMLElement).closest('.link-action') as HTMLButtonElement)?.dataset;
		if (!dataset) return;

		const { id, action } = dataset as { id: string; action: TLinkAction };
		relationshipAction({
			projectId,
			relationshipId: id,
			method: action === 'add' ? DEFAULTS.httpMethod.post : DEFAULTS.httpMethod.delete,
			relationships: action === 'add' ? undefined : [id],
		});
	};

	useEffect(() => {
		if (!relationshipActionResult) return;
		const { isError, error, isSuccess, data } = relationshipActionResult;
		if (isError) return showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
		if (isSuccess) {
			if (data.action === 'add') {
				if (!data.relationshipId) return;
				const link = links?.relationships[data.relationshipId];
				if (!link || !entity) return;
				const complementaryEntity = {
					id: link.complementary_entity_id,
					title: link.entity_title,
					entity_type: link.entity_type_id,
					logo: link.entity_logo,
				} satisfies TEntityForNode;
				upsertEdge(
					{
						id: link.id,
						entity_id_1: link.direct_order ? entity.id : complementaryEntity.id,
						entity_id_2: link.direct_order ? complementaryEntity.id : entity.id,
						relationship_type_id: link.relationship_type_id,
					},
					entity,
					complementaryEntity
				);
			} else {
				if (!data.relationships) return;
				for (const relationshipId of data.relationships) {
					removeEdge(relationshipId);
				}
			}
			dispatch(changeNetworkStateTs());
		}
	}, [relationshipActionResult]); // eslint-disable-line

	return (
		<div className="mt-2" onClick={onButtonActionClick}>
			<h4 className="text-center">Зв'язки об'єкта</h4>
			{error && <FetchError error={error} />}
			{links && entity && (
				<RelationshipsByTypes entity={entity} isProjectEditor={isProjectEditor} links={links} userRights={userRights} />
			)}
			{entity && isFetching && <DotSpinner>Завантаження інформації про зв'язки об'єкта</DotSpinner>}
		</div>
	);
};

export default EntityLinks;
