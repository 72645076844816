import { DEFAULTS } from '../../common';
import {
	IEntity,
	IEntityEditMethod,
	IInvalidatedTags,
	IUpdateResponse,
	QUERY_TAGS,
	TMutatingRow,
	TStringOrNull,
} from '../../interfaces/common';
import { IEntityBase, IEntityObject, TEntityAlternativeTitles } from '../../interfaces/entities';
import { photonApi } from './photonApi';

type TMutatingEntity = TMutatingRow<IEntityBase, 'id'>;
export type TEntityUpdate = TMutatingEntity & { alternativeTitles?: TEntityAlternativeTitles };

export interface IEntitySearchQueryParams extends Record<string, string> {
	term: string;
	pId: string;
	count: string;
}
export interface IEntitySearch {
	id: number;
	entity_type: number;
	country: string;
	title: string;
	logo: TStringOrNull;
	sml: number;
	alternative_titles: TEntityAlternativeTitles;
}

export interface IEntityLinksQueryParams {
	eId: number;
	pId: number;
}
export interface IEntityLink {
	id: string;
	created_at: string;
	creator_email: string;
	relationship_type_id: number;
	creator_unit_id: number;
	complementary_entity_id: number;
	direct_order: boolean;
	entity_title: string;
	entity_type_id: number;
	entity_logo: TStringOrNull;
	inserted_at: TStringOrNull;
	description: string;
}
export interface IEntityLinksByType extends IEntity<number, string[]> {
	relationships: Record<string, IEntityLink>;
}

const entityApi = photonApi.injectEndpoints({
	endpoints: (builder) => ({
		entity: builder.query<IEntityObject, number>({
			query: (entityId) => `entity/${entityId}`,
			providesTags: (result, error, entityId) => [{ type: QUERY_TAGS.entity, id: entityId }],
		}),
		editEntity: builder.mutation<IEntityObject & IUpdateResponse<number>, TEntityUpdate & IEntityEditMethod>({
			query: ({ method, ...rest }) => ({
				url: 'edit/entity',
				method,
				body: rest,
			}),
			invalidatesTags: (result, error, { method, id }) => [
				{ type: QUERY_TAGS.entity, id: method === DEFAULTS.httpMethod.put ? id : 'list' },
			],
			// invalidatesTags: (result, error, { method, id }) => {
			// 	const invalidatedTags: IInvalidatedTags[] =
			// 		method === DEFAULTS.httpMethod.put ? [] : [{ type: QUERY_TAGS.entity, id: 'list' }];
			// 	if (method !== DEFAULTS.httpMethod.post) invalidatedTags.push({ type: QUERY_TAGS.entity, id: id });
			// 	return invalidatedTags;
			// },
		}),
		entitySearch: builder.query<IEntitySearch[], IEntitySearchQueryParams>({
			query: (params) => `entity/search?${new URLSearchParams(params).toString()}`,
			providesTags: (result) =>
				[{ type: QUERY_TAGS.entity, id: 'list' } as IInvalidatedTags].concat(
					result?.map(({ id }) => ({ type: QUERY_TAGS.entity, id })) ?? []
				),
		}),
		entityLinks: builder.query<IEntityLinksByType, IEntityLinksQueryParams>({
			query: ({ eId, pId }) => `entity/relationships?eId=${eId}&pId=${pId}`,
			providesTags: (result) => {
				const defaultTags: IInvalidatedTags[] = [
					{ type: QUERY_TAGS.entity, id: 'list' },
					{ type: QUERY_TAGS.relationship, id: 'list' },
				];
				if (!result) return defaultTags;

				const entities: Set<number | undefined> = new Set();
				const links: Set<string | undefined> = new Set();
				for (const link of Object.values(result.relationships)) {
					entities.add(link.complementary_entity_id);
					links.add(link.id);
				}

				return [
					...defaultTags,
					...Array.from(entities).map((id) => ({ type: QUERY_TAGS.entity, id })),
					...Array.from(links).map((id) => ({ type: QUERY_TAGS.relationship, id })),
				];
			},
		}),
	}),
});

export const { useEntityQuery, useEditEntityMutation, useEntitySearchQuery, useEntityLinksQuery } = entityApi;
