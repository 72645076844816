import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

interface IImageZoomProps extends React.AllHTMLAttributes<HTMLImageElement> {
	onClose: () => void;
}
const ImageZoom = ({ src, alt, onClose }: IImageZoomProps) => {
	const [show, setShow] = useState(true);

	const onDialogClose = () => {
		setShow(false);
		setTimeout(onClose, 400);
	};

	return (
		<Modal
			centered
			show={show}
			onHide={onDialogClose}
			dialogClassName="modal-zoom"
			onClick={onDialogClose}
			backdropClassName="double-modal-backdrop"
		>
			<img src={src} className="align-self-center rounded" alt={alt} />
		</Modal>
	);
};

export default ImageZoom;
