import { Col, Container, Row } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import { FixedDotSpinner } from '../../components/DotSpinner';
import FetchError from '../../components/FetchError';
import TextIcon from '../../components/TextIcon';
import { TProjectContext } from '../../interfaces/projects';
import ProjectContentStatistic from '../projects/ProjectContentStatistic';
import { useProjectSummaryQuery } from '../services/project';
import EntitySummary from './EntitySummary';
import SummaryList from './SummaryList';

// interface IProjectSummaryProps extends React.AllHTMLAttributes<HTMLDivElement>{}
const ProjectSummary = () => {
	const { projectId } = useOutletContext<TProjectContext>();
	const { data, error, isFetching } = useProjectSummaryQuery(projectId, {
		refetchOnMountOrArgChange: true,
	});

	return (
		<SummaryContainer fluid="xl">
			<Row>
				<Col>
					<h1 className="text-center mb-0">Опис проекта</h1>
					<ProjectContentStatistic
						entitiesCount={data?.entities.ids.length}
						relationshipsCount={data?.relationships ? Object.keys(data.relationships).length : undefined}
						className="h2 justify-content-center gap-3"
						withLabels
					/>
				</Col>
			</Row>
			<Link
				to=".."
				className="btn btn-outline-danger position-fixed end-0 top-main mt-2 me-2 d-print-none"
				title="Назад до схеми"
			>
				<TextIcon Icon={BsArrowLeft} size="lg">
					До схеми
				</TextIcon>
			</Link>
			{error && <FetchError error={error} />}
			{data && (
				<>
					<SummaryList entities={data.entities} />
					<Row>
						<Col>
							<h2 className="text-center">Опис об'єктів та зв'язків</h2>
						</Col>
					</Row>
					{data.entities.ids.map((id, index) => (
						<EntitySummary
							key={id}
							serialNo={index + 1}
							entityId={id}
							entities={data.entities.entities}
							relationships={data.relationships}
							entityRelationships={data.entityRelationships[id]}
						/>
					))}
				</>
			)}
			{isFetching && <FixedDotSpinner>Завантаження інформації про проект</FixedDotSpinner>}
		</SummaryContainer>
	);
};

export default ProjectSummary;

const SummaryContainer = styled(Container)`
	p {
		font-size: 22px;
	}
	@media print {
		font-size: 12pt;
		p {
			font-size: 12pt;
		}
	}
`;
