import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsCrosshair } from 'react-icons/bs';
import PrintableHashLink from '../../components/PrintableHashLink';
import WithDictionaries, { IWithDictionaries } from '../../components/hoc/withDictionaries';
import { IEntity } from '../../interfaces/common';
import EntityTitleWithType from '../entities/EntityTitleWithType';
import { ISummaryEntity } from '../services/project';

interface ISummaryListProps extends IWithDictionaries, React.AllHTMLAttributes<HTMLDivElement> {
	entities: IEntity<number, ISummaryEntity>;
}
const SummaryList = ({ entities: { ids, entities }, entityTypes: { entities: entityTypes } }: ISummaryListProps) => {
	return (
		<Row>
			<Col>
				<h2 className="text-center">Перелік об'єктів</h2>
				<ol>
					{ids.map((id) => {
						const entity = entities[id];
						if (!entity) return null;
						return (
							<li key={id}>
								<PrintableHashLink targetId={`card_entity_${id}`} className={entity.is_init ? 'fw-bolder' : undefined}>
									{entity.is_init && <BsCrosshair className="me-1 mt-n1" />}
									<EntityTitleWithType title={entity.title} typeId={entity.entity_type} />
								</PrintableHashLink>
								<span className="ms-1 text-muted">({entityTypes[entity.entity_type]?.title})</span>
							</li>
						);
					})}
				</ol>
			</Col>
		</Row>
	);
};

export default WithDictionaries(SummaryList);
