import classNames from 'classnames';
import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { moveControlToScreenCenter } from '../scrolls';

interface IPrintableHashLinkProps extends React.AllHTMLAttributes<HTMLDivElement> {
	targetId: string;
	printDisplayMethod?: string;
}
const PrintableHashLink = ({
	targetId,
	children,
	printDisplayMethod = 'inline',
	className,
}: IPrintableHashLinkProps) => {
	const hash = `#${targetId}`;

	const onButtonClick = useCallback(() => {
		moveControlToScreenCenter(document.getElementById(targetId));
	}, [targetId]);

	return (
		<>
			<a href={hash} className={classNames(className, 'd-none', `d-print-${printDisplayMethod}`)}>
				{children}
			</a>
			<Button
				variant="link"
				className={classNames('p-0 d-print-none btn-with-no-size', className)}
				onClick={onButtonClick}
			>
				{children}
			</Button>
		</>
	);
};

export default PrintableHashLink;
