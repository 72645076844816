import { useEffect } from 'react';
import styled from 'styled-components';
import Dwarf from '../img/photon-app.webp';
import LoginButton from './LoginButton';

const SplashScreen = () => {
	useEffect(() => {
		const body = document.querySelector('body');
		if (!body) return;

		body.classList.add('not-auth');

		return () => body.classList.remove('not-auth');
	}, []);

	return (
		<SplashContainer className="d-flex flex-column gap-4 align-items-center m-auto">
			<LogoImage src={Dwarf} />
			<LoginButton large />
		</SplashContainer>
	);
};

export default SplashScreen;

const SplashContainer = styled.div`
	padding-bottom: 64px;
`;

interface IDwarfImageProps {
	height?: string;
}
const LogoImage = styled.img<IDwarfImageProps>`
	height: ${({ height }) => height || '38vh'};
	width: auto;
	opacity: 0.75;
	filter: drop-shadow(0 0 5px white);
`;
