import classNames from 'classnames';
import React, { useCallback } from 'react';
import { BsPatchCheckFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { IdType } from 'vis-network/standalone';
import { selectAndFocusNode } from '../schemeUtils';

interface IInProjectEntityPatchProps extends React.AllHTMLAttributes<HTMLDivElement> {
	entityId: IdType;
}
const InProjectEntityPatch = ({ className, entityId }: IInProjectEntityPatchProps) => {
	const onTargetClick = useCallback(() => {
		selectAndFocusNode(entityId);
	}, [entityId]);

	return (
		<Link to=".." className={classNames('in-project-path', className)} title="Об'єкт в проекті" onClick={onTargetClick}>
			<BsPatchCheckFill />
		</Link>
	);
};

export default InProjectEntityPatch;
