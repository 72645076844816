import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import { BsPencilSquare } from 'react-icons/bs';
import { IUnitDic } from '../../../interfaces/dicts';

interface IUnitItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	unit?: IUnitDic;
}
const UnitItem = ({ unit }: IUnitItemProps) => {
	// Ця строка більше потрібна для відповідності типам
	if (!unit) return null;

	return (
		<tr>
			<td>
				<Badge bg="secondary">{unit.id}</Badge>
			</td>
			<td id={`unit_${unit.id}`}>{unit.code}</td>
			<td>
				<Button variant="link" size="sm" data-unit-id={unit.id} className="btn-edit p-0" title="Редагувати">
					<BsPencilSquare />
					<span className="ms-1 d-none d-lg-inline">Редагувати</span>
				</Button>
			</td>
		</tr>
	);
};

export default UnitItem;
