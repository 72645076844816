import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import styled from 'styled-components';
import { scrollWindowToTop } from '../scrolls';
import FixedPanelButton from './FixedPanelButton';

export const TopScroller = () => {
	// const { pathname } = useLocation();

	const scrollToTop = () => scrollWindowToTop('smooth');

	// useEffect(() => {
	// 	setTimeout(scrollWindowToTop, 100, 'auto');
	// }, [pathname]);

	return (
		<ScrollButton
			Icon={BsFillArrowUpCircleFill}
			onClick={scrollToTop}
			className="d-print-none"
			title="Догори сторінки"
		/>
	);
};

const ScrollButton = styled(FixedPanelButton)`
	--fixed-button-color: var(--bs-primary, blue);
`;
