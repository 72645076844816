import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import { showError, showPromiseConfirm } from '../../../alerts';
import { DEFAULTS, DELETION_WARNING, EXIT_CONFIRMATION } from '../../../common';
import Asterisk from '../../../components/Asterisk';
import DialogFooter from '../../../components/DialogFooter';
import TextIcon from '../../../components/TextIcon';
import { useFormFields } from '../../../hooks';
import { IUnitDic } from '../../../interfaces/dicts';
import DangerousListItem from '../../dangerous/DangerousListItem';
import DangerousZone from '../../dangerous/DangerousZone';
import { useUnitMutation } from '../../services/manage';

const DEFAULT_UNIT: IUnitDic = {
	id: 0,
	code: '',
};
interface IUnitDialogProps extends React.AllHTMLAttributes<HTMLDivElement> {
	unit?: IUnitDic;
	onClose: (unitId?: number) => void;
}
const UnitDialog = ({ unit, onClose }: IUnitDialogProps) => {
	const inserting = unit === undefined;
	const [initialData, setInitialData] = useState<IUnitDic>(DEFAULT_UNIT);
	const { formFields, createChangeHandler, modified } = useFormFields(initialData);
	const refFirstControl = useRef<HTMLInputElement>(null);
	const [updateUnit, updateUnitResult] = useUnitMutation();

	useEffect(() => {
		refFirstControl.current?.focus();
	}, []);

	useEffect(() => {
		if (!unit) return;
		setInitialData({ ...unit });
	}, [unit]);

	useEffect(() => {
		if (!updateUnitResult) return;
		const { isError, error, isSuccess, data } = updateUnitResult;
		if (isSuccess) return closeDialog(data.id);
		if (isError) showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
		refFirstControl.current?.focus();
	}, [updateUnitResult]); // eslint-disable-line

	const closeDialog = (unitId?: number) => {
		onClose(unitId);
	};

	const handleClose = async () => {
		if (modified && !(await showPromiseConfirm(EXIT_CONFIRMATION))) return;
		closeDialog();
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		// const dataToSave = getDataForSave(inserting ? undefined : initialData, formFields, ['id']);
		updateUnit({
			...formFields,
			method: inserting ? DEFAULTS.httpMethod.post : DEFAULTS.httpMethod.put,
		});
	};

	const onDeleteClick = async () => {
		if (!(await showPromiseConfirm(`Ви впевнені, що хочете видалити запис підрозділу «${formFields.code}»?`))) return;
		await updateUnit({ id: formFields.id, method: DEFAULTS.httpMethod.delete });
	};

	return (
		<Modal
			show
			centered
			// size="lg"
			fullscreen="sm-down"
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<TextIcon Icon={BsPencilSquare} className="mt-n1 me-1" />
					Редагування підрозділу
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={onSubmit} id="fmUnit">
					<Form.Group controlId="unitId">
						<Form.Label>
							Ідентифікаційний номер
							<Asterisk />
						</Form.Label>
						<Form.Control
							type="number"
							min={1}
							max={1000}
							required
							placeholder="Введіть ідентифікатор (id)"
							value={formFields.id || ''}
							onChange={createChangeHandler('id')}
							autoComplete="off"
							ref={inserting ? refFirstControl : undefined}
							disabled={!inserting}
						/>
					</Form.Group>
					<Form.Group controlId="unitCode" className="mt-3">
						<Form.Label>
							Кодова назва
							<Asterisk />
						</Form.Label>
						<Form.Control
							type="text"
							required
							placeholder="Введіть кодову назву"
							maxLength={20}
							value={formFields.code}
							onChange={createChangeHandler('code')}
							autoComplete="off"
							ref={inserting ? undefined : refFirstControl}
						/>
					</Form.Group>
					{!inserting && (
						<DangerousZone className="mt-3">
							<DangerousListItem
								onClick={onDeleteClick}
								title="Видалити запис даного підрозділу."
								description={DELETION_WARNING}
							>
								<TextIcon Icon={BsTrash}>Видалити</TextIcon>
							</DangerousListItem>
						</DangerousZone>
					)}
				</Form>
			</Modal.Body>
			<DialogFooter
				formId="fmUnit"
				entityId={inserting ? undefined : initialData.id}
				disabled={!modified || updateUnitResult.isLoading}
				isLoading={updateUnitResult.isLoading}
				onClose={handleClose}
			/>
		</Modal>
	);
};

export default UnitDialog;
