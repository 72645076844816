import React from 'react';
import { useUnitsQuery } from '../../features/services/photonApi';
import { ENTITY_STATE_STUB, TUnitDictionary } from '../../interfaces/dicts';

export interface IWithUnits {
	units: TUnitDictionary;
	unitsError: any;
	unitsIsFetching: boolean;
}

const WithUnits =
	<T extends IWithUnits>(WrappedComponent: React.ComponentType<T>) =>
	(props: Omit<T, keyof IWithUnits>) => {
		// eslint-disable-next-line
		const { data, error, isFetching } = useUnitsQuery(undefined, {
			refetchOnMountOrArgChange: false,
		});

		return (
			<WrappedComponent
				{...(props as T)}
				units={data || ENTITY_STATE_STUB}
				unitsError={error}
				unitsIsFetching={isFetching}
			/>
		);
	};

export default WithUnits;
