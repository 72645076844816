import { IEntityEditMethod, IUpdateResponse, QUERY_TAGS, TMutatingRow } from '../../interfaces/common';
import { IUnitDic } from '../../interfaces/dicts';
import { IUser, IUsers } from '../../interfaces/users';
import { photonApi } from './photonApi';

type TMutatingUser = TMutatingRow<IUser, 'id'>;
type TMutatingUnit = TMutatingRow<IUnitDic, 'id'>;

const manageApi = photonApi.injectEndpoints({
	endpoints: (builder) => ({
		userList: builder.query<IUsers, void>({
			query: () => 'manage/user/list',
			providesTags: [{ type: QUERY_TAGS.user, id: 'list' }],
		}),
		user: builder.mutation<IUpdateResponse<number>, TMutatingUser & IEntityEditMethod>({
			query: ({ method, ...rest }) => ({
				url: 'manage/edit/user',
				method,
				body: rest,
			}),
			invalidatesTags: [{ type: QUERY_TAGS.user, id: 'list' }],
		}),
		unit: builder.mutation<IUpdateResponse<number>, TMutatingUnit & IEntityEditMethod>({
			query: ({ method, ...rest }) => ({
				url: 'manage/edit/unit',
				method,
				body: rest,
			}),
			invalidatesTags: [{ type: QUERY_TAGS.unit, id: 'list' }],
		}),
	}),
});

export const { useUserListQuery, useUserMutation, useUnitMutation } = manageApi;

export const getUserLabel = (user?: IUser, manager?: IUser | null) => `${user?.email} ⛏️ ${manager?.email ?? 'null'}`;

// export const getUserLabel = (user?: IUser, full = true) =>
// 	user ? (full ? `${user.email} ⛏️ ${user.managerEmail ?? 'null'}` : user.email) : 'null';
