import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';
import { showError, showPromiseConfirm } from '../../../alerts';
import { DEFAULTS, DELETION_WARNING, EXIT_CONFIRMATION } from '../../../common';
import Asterisk from '../../../components/Asterisk';
import CommonSelector from '../../../components/CommonSelector';
import DialogFooter from '../../../components/DialogFooter';
import TextIcon from '../../../components/TextIcon';
import { useFormFields } from '../../../hooks';
import { IDictionary } from '../../../interfaces/common';
import { TUnitDictionary } from '../../../interfaces/dicts';
import { IUser } from '../../../interfaces/users';
import { getDataForSave } from '../../../utils';
import DangerousListItem from '../../dangerous/DangerousListItem';
import DangerousZone from '../../dangerous/DangerousZone';
import { useUserMutation } from '../../services/manage';

const DEFAULT_USER: IUser = {
	id: 0,
	email: '',
	manager: null,
	is_active: true,
	unit_id: 0,
};
interface IUserDialogProps extends React.AllHTMLAttributes<HTMLDivElement> {
	user?: IUser;
	onClose: (userId?: number) => void;
	users: IDictionary<number, IUser>;
	units: TUnitDictionary;
}
const UserDialog = ({ user, onClose, users, units }: IUserDialogProps) => {
	const inserting = user === undefined;
	const [initialData, setInitialData] = useState<IUser>(DEFAULT_USER);
	const { formFields, createChangeHandler, modified, createSetHandler } = useFormFields(initialData);
	const refFirstControl = useRef<HTMLInputElement>(null);
	const [updateUser, updateUserResult] = useUserMutation();

	useEffect(() => {
		refFirstControl.current?.focus();
	}, []);

	useEffect(() => {
		if (!user) return;
		setInitialData({ ...user });
	}, [user]); // eslint-disable-line

	useEffect(() => {
		if (!updateUserResult) return;
		const { isError, error, isSuccess, data } = updateUserResult;
		if (isSuccess) return closeDialog(data.id);
		if (isError) showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
		refFirstControl.current?.focus();
	}, [updateUserResult]); // eslint-disable-line

	const closeDialog = (userId?: number) => {
		onClose(userId);
	};

	const handleClose = async () => {
		if (modified && !(await showPromiseConfirm(EXIT_CONFIRMATION))) return;
		closeDialog();
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		if (!formFields.unit_id) return showError('Поле підрозділу має бути заповнене');
		const dataToSave = getDataForSave(inserting ? undefined : initialData, formFields, ['id']);
		updateUser({
			...dataToSave,
			method: inserting ? DEFAULTS.httpMethod.post : DEFAULTS.httpMethod.put,
		});
	};

	const onDeleteClick = async () => {
		if (!(await showPromiseConfirm(`Ви впевнені, що хочете видалити запис користувача «${formFields.email}»?`))) return;
		await updateUser({ id: formFields.id, method: DEFAULTS.httpMethod.delete });
	};

	return (
		<Modal
			show
			centered
			// size="lg"
			fullscreen="sm-down"
			onHide={handleClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<TextIcon Icon={BsPencilSquare} className="mt-n1 me-1" />
					Редагування користувача
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={onSubmit} id="fmUser">
					{/* <Row className="g-3"> */}
					<Form.Group controlId="userEmail">
						<Form.Label>
							Email
							<Asterisk />
						</Form.Label>
						<Form.Control
							type="email"
							required
							placeholder="Введіть електронну адресу"
							maxLength={50}
							value={formFields.email}
							onChange={createChangeHandler('email')}
							autoComplete="off"
							ref={refFirstControl}
						/>
					</Form.Group>
					<CommonSelector
						onItemChange={createSetHandler('unit_id', 'number')}
						selectedId={formFields.unit_id}
						containerClassName="mt-3"
						allItems={units.entities}
						label="Підрозділ"
						required
						fieldName="unit_id"
						labelName="code"
						valueName="id"
					/>
					<CommonSelector
						onItemChange={createSetHandler('manager', 'number')}
						selectedId={formFields.manager}
						containerClassName="mt-3"
						allItems={users}
						label="Керівник"
						required={false}
						fieldName="manager"
						labelName="email"
						valueName="id"
					/>
					<Form.Check
						type="checkbox"
						label="Запис є активним"
						id="userIsActive"
						checked={formFields.is_active}
						onChange={createChangeHandler('is_active', 'boolean')}
						className="mt-3"
					/>
					{!inserting && (
						<DangerousZone className="mt-3">
							<DangerousListItem
								onClick={onDeleteClick}
								title="Видалити запис даного користувача."
								description={DELETION_WARNING}
							>
								<TextIcon Icon={BsTrash}>Видалити</TextIcon>
							</DangerousListItem>
						</DangerousZone>
					)}
				</Form>
			</Modal.Body>
			<DialogFooter
				formId="fmUser"
				entityId={inserting ? undefined : initialData.id}
				disabled={!modified || updateUserResult.isLoading}
				isLoading={updateUserResult.isLoading}
				onClose={handleClose}
			/>
		</Modal>
	);
};

export default UserDialog;
