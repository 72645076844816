import { useCallback, useEffect, useState } from 'react';
import { TObjectType } from './interfaces/common';
import { differentContentInArray, objectsAreEquals, targetIsNotEquals } from './utils';

// export const useScreenWidth = (width: number) => {
// 	const [isSmaller, setIsSmaller] = useState(window.innerWidth < width);

// 	useEffect(() => {
// 		function handleResize() {
// 			setIsSmaller(window.innerWidth < width);
// 		}

// 		window.addEventListener('resize', handleResize);
// 		return () => window.removeEventListener('resize', handleResize);
// 	}, [width]);

// 	return [isSmaller];
// };

export function useFormFields<T extends TObjectType>(initialFields: T) {
	type TValue = string | number | boolean | null;
	const [formFields, setFormFields] = useState<T>({ ...initialFields });
	const [modified, setModified] = useState<boolean>(false);
	/**
	 * В багатьох випадках цьому хуку на початку передаються дані по замовчанню, а лише потім
	 * реальні дані. В такому випадку modified буде швидко "смикатись" через спрацювання Side Effects.
	 * Для уникнення цього смикання тут і використовується useDebounce.
	 */
	const { debouncedValue: debouncedModified } = useDebounce(modified, 50);

	useEffect(() => {
		if (!objectsAreEquals(initialFields, formFields)) setFormFields({ ...initialFields });
	}, [initialFields]); // eslint-disable-line

	useEffect(() => {
		setModified(targetIsNotEquals(initialFields, formFields));
	}, [initialFields, formFields]);

	/**
	 * За бізнес-логікою додатку поле типу boolean реалізовано за допомогою
	 * типу number, що може містити значення 1 або 0
	 */
	const createChangeHandler = useCallback(
		(key: keyof T, fieldType: TValue = 'string') =>
			(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
				const value = event.target?.value;
				setFormFields((prev: T) => ({
					...prev,
					[key]:
						fieldType === 'string'
							? value
							: fieldType === 'number'
							? parseInt(value, 10) ?? null
							: (event.target as HTMLInputElement).checked
							? 1
							: 0,
				}));
			},
		[]
	);

	// Дана функція використовується для прямої зміни значення атрибута
	const createSetHandler = useCallback(
		(key: keyof T, fieldType: TValue = 'string') =>
			(value: TValue) =>
				setFormFields((prev: T) => ({
					...prev,
					[key]: fieldType !== 'boolean' ? value : value ? 1 : 0,
				})),
		[]
	);

	const resetFormFields = useCallback(() => {
		setFormFields({ ...initialFields });
	}, [initialFields]);

	return {
		formFields,
		createChangeHandler,
		createSetHandler,
		setFormFields,
		resetFormFields,
		modified: debouncedModified,
	};
}

export const useDebounce = <T>(initialValue: T, delay: number = 500) => {
	const [term, setTerm] = useState(initialValue);
	const [debouncedValue, setDebouncedValue] = useState(initialValue);

	useEffect(() => {
		setTerm(initialValue);
	}, [initialValue]);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(term);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [term, delay]);

	const onTermChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newTerm = event.target.value as unknown as T;
			setTerm(newTerm);
		},
		[setTerm]
	);

	return { term, setTerm, onTermChange, debouncedValue };
};

export interface IWithPageNo {
	pageNo: number;
}
export const usePageNo = <T>(
	initialValue: T
): [T & IWithPageNo, (newData: Partial<T>) => void, (newPageNo: number) => void] => {
	const [value, setValue] = useState<T & IWithPageNo>(() => ({ ...initialValue, pageNo: 0 }));

	const setPageNo = (newPageNo: number) => {
		if (newPageNo === value.pageNo) return;
		setValue((value) => ({ ...value, pageNo: newPageNo }));
	};

	const setData = useCallback((newData: Partial<T>) => {
		setValue((value) => ({ ...value, ...newData, pageNo: 0 }));
	}, []);

	return [value, setData, setPageNo];
};

export const useArray = <T>(initialData?: T[]) => {
	const [data, setData] = useState<T[]>(() => initialData || []);
	const [modified, setModified] = useState(false);

	useEffect(() => {
		if (!initialData) return;
		if (differentContentInArray(data, initialData)) setData(initialData);
	}, [initialData]); // eslint-disable-line

	useEffect(() => {
		setModified(differentContentInArray(initialData || [], data));
	}, [data]); // eslint-disable-line

	const setDataIfDifferent = (newData?: T[]) => {
		if (!newData) return;
		if (differentContentInArray(data, newData)) setData([...new Set(newData)]);
	};

	const toggleItem = (id: T) => {
		setData(data.includes(id) ? data.filter((itemId) => itemId !== id) : [...data, id]);
	};
	// console.log(data);

	return { data, setData: setDataIfDifferent, toggleItem, modified };
};

export const useEscapePress = (callback: () => void) => {
	const onKeyPress = useCallback(
		(event: KeyboardEvent) => {
			if (event.code === 'Escape') callback();
		},
		[callback]
	);

	useEffect(() => {
		document.addEventListener('keydown', onKeyPress);
		return () => document.removeEventListener('keydown', onKeyPress);
	}, [onKeyPress]);
};
