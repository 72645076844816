import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { BsSearch, BsXLg } from 'react-icons/bs';
import { useDebounce } from '../hooks';
import { scrollWindowToTop } from '../scrolls';
import TextIcon from './TextIcon';

interface ISearchFormProps {
	initialTerm?: string;
	onSubmit: (term: string) => void;
	className?: string;
	children?: JSX.Element;
	disabled?: boolean;
	variant?: string;
	formId: string;
	focusOnInit?: boolean;
	withClearButton?: boolean;
	onChange?: (term: string) => void;
}
const SearchForm = ({
	className,
	initialTerm = '',
	onSubmit,
	children,
	disabled = false,
	variant = 'primary',
	formId,
	focusOnInit,
	withClearButton,
	onChange,
}: ISearchFormProps) => {
	const [term, setTerm] = useState(initialTerm);
	const refSearch = useRef<HTMLInputElement>(null);
	const { debouncedValue, setTerm: setDebounceTerm } = useDebounce(initialTerm);

	useEffect(() => {
		// Якщо onChange відсутня, то немає сенсу змінювати debouncedValue
		if (onChange) setDebounceTerm(term);
	}, [term, setDebounceTerm, onChange]);

	useEffect(() => {
		if (onChange && debouncedValue.length !== 1) onChange(debouncedValue);
	}, [debouncedValue, onChange]);

	const onTermChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setTerm(event.target.value), []);

	const focusInput = useCallback(() => {
		if (!focusOnInit) return;
		refSearch.current?.focus();
		setTimeout(() => refSearch.current?.select(), 0);
	}, [focusOnInit]);

	const onSearchFormSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		event.stopPropagation();
		onSubmit(term);
		focusInput();
		scrollWindowToTop();
	};

	useEffect(() => {
		focusInput();
	}, []); // eslint-disable-line

	useEffect(() => {
		setTerm(initialTerm);
	}, [initialTerm]);

	const onClearButtonClick = useCallback(() => {
		setTerm('');
	}, []);

	return (
		<form id={formId} className={className} role="search" onSubmit={onSearchFormSubmit}>
			<InputGroup>
				{children}
				<Form.Control
					name="term"
					// accessKey="f"
					type="text"
					className="form-control"
					placeholder="Введіть підстроку для пошуку..."
					maxLength={300}
					autoComplete="off"
					value={term}
					onChange={onTermChange}
					ref={refSearch}
					disabled={disabled}
				/>
				<Button variant={variant} type="submit" disabled={disabled}>
					<TextIcon Icon={BsSearch} size="lg">
						Знайти
					</TextIcon>
				</Button>
				{withClearButton && (
					<Button variant="danger" onClick={onClearButtonClick}>
						<BsXLg />
					</Button>
				)}
			</InputGroup>
		</form>
	);
};

export default SearchForm;
