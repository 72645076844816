import { Auth0Provider } from '@auth0/auth0-react';
import 'bootstrap/scss/bootstrap.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { DEFAULTS, getTokenSilentlyAuthOptions } from './common';
import App from './features/app/App';
import './index.scss';
import { updateThemeInPage } from './interfaces/common';
import store from './store';

updateThemeInPage();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Auth0Provider
				domain={
					process.env.NODE_ENV === 'production'
						? (process.env.REACT_APP_PROD_AUTH0_DOMAIN as string)
						: (process.env.REACT_APP_DEV_AUTH0_DOMAIN as string)
				}
				clientId={
					process.env.NODE_ENV === 'production'
						? (process.env.REACT_APP_PROD_AUTH0_CLIENT_ID as string)
						: (process.env.REACT_APP_DEV_AUTH0_CLIENT_ID as string)
				}
				authorizationParams={{
					redirect_uri: window.location.href,
					...getTokenSilentlyAuthOptions(DEFAULTS.availableScopes, true).authorizationParams,
				}}
				useRefreshTokens={true}
				// Два останні параметри впливають на оновлення токену. Більш подробно тут:
				// https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/28?u=tougaj
				// cacheLocation="localstorage"
				useRefreshTokensFallback={true}
			>
				<App />
			</Auth0Provider>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
