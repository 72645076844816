import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import { ButtonGroup, ButtonToolbar, Form } from 'react-bootstrap';
import styled from 'styled-components';
import Asterisk from '../Asterisk';

export interface IActionButtonData {
	contentBefore?: string;
	contentAfter: string;
	title: string;
	caption?: string;
}

const INSERTION_DEFAULT_DATA: IActionButtonData[] = [
	{
		// contentBefore: '',
		contentAfter: '’',
		title: 'апостроф',
	},
	{
		contentBefore: '«',
		contentAfter: '»',
		title: 'кутові лапки',
	},
	{
		contentBefore: '“',
		contentAfter: '”',
		title: 'лапки',
	},
];

interface IDescriptionControlProps extends React.AllHTMLAttributes<HTMLTextAreaElement> {
	controlId: string;
	controlClassName?: string;
	value: string;
	onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
	onValueChange?: (newValue: string) => void;
	actionButtonsData?: (IActionButtonData | null)[];
}
const DescriptionControl = ({
	controlId,
	controlClassName,
	title = 'Опис',
	required,
	value,
	onChange,
	rows,
	className,
	onValueChange,
	disabled,
	actionButtonsData,
	...rest
}: IDescriptionControlProps) => {
	const refArea = useRef<HTMLTextAreaElement>(null);

	const onEditButtonClick = useCallback(
		async (event: React.MouseEvent<HTMLDivElement>) => {
			const { contentBefore, contentAfter } = ((event.target as HTMLElement).closest('.btn') as HTMLButtonElement)
				.dataset;
			const textarea = refArea.current;
			if (!(contentBefore || contentAfter) || !textarea || !onValueChange) return;

			var start = textarea.selectionStart;
			var end = textarea.selectionEnd;
			var selectedText = textarea.value.substring(start, end);
			var newText = contentBefore + selectedText + contentAfter;
			const newValue = textarea.value.substring(0, start) + newText + textarea.value.substring(end);
			onValueChange(newValue);

			textarea.focus();
			setTimeout(() => {
				// textarea.setSelectionRange(start, start + newText.length);
				// const cursorPosition = start + (start === end ? 1 : newText.length);
				const cursorPosition = start + newText.length;
				textarea.setSelectionRange(cursorPosition, cursorPosition);
			}, 100);
		},
		[onValueChange]
	);

	return (
		<Form.Group controlId={controlId} className={classNames('d-flex flex-column', controlClassName)}>
			<Form.Label className="hstack mb-0 align-items-center">
				{title}
				{required && <Asterisk />}
				{onValueChange && !disabled && (
					<ButtonToolbar className="ms-auto font-serif mt-n1">
						{actionButtonsData && (
							<ButtonGroup size="sm" onClick={onEditButtonClick} className="me-2">
								{(actionButtonsData.filter(Boolean) as IActionButtonData[]).map(
									({ contentBefore = '', contentAfter, title, caption }) => (
										<ContentButton
											key={contentAfter}
											type="button"
											data-content-before={contentBefore}
											data-content-after={contentAfter}
											className="btn btn-outline-secondary text-truncate"
											title={'Вставити: ' + title}
										>
											{caption}
										</ContentButton>
									)
								)}
							</ButtonGroup>
						)}
						<ButtonGroup size="sm" onClick={onEditButtonClick}>
							{INSERTION_DEFAULT_DATA.map(({ contentBefore = '', contentAfter, title }) => (
								<ContentButton
									key={contentAfter}
									type="button"
									data-content-before={contentBefore}
									data-content-after={contentAfter}
									// data-text={`${contentBefore}${contentAfter}`}
									className="btn btn-outline-secondary"
									title={'Вставити: ' + title}
								>
									{contentBefore}A{contentAfter}
								</ContentButton>
							))}
						</ButtonGroup>
					</ButtonToolbar>
				)}
			</Form.Label>
			<Form.Control
				ref={refArea}
				as="textarea"
				value={value}
				onChange={onChange}
				rows={rows}
				className={classNames(className, !rows && 'min-height-38vh')}
				required={required}
				disabled={disabled}
				{...(rest as any)}
			/>
			{/* <DescriptionArea value={value} onChange={onChange} required={required} {...rest} /> */}
		</Form.Group>
	);
};

export default DescriptionControl;

const ContentButton = styled.button`
	min-width: 3em;
	max-width: 15em;
`;
