import React from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { BsBoxes, BsFolder, BsPersonFill, BsShieldLock } from 'react-icons/bs';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { DEFAULTS } from '../common';
// import UploadButton from '../features/uploads/UploadButton';
// import SearchForm from '../features/search/SearchForm';
import Logo from '../img/logo.webp';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import TextIcon from './TextIcon';

interface INavHeaderProps extends React.AllHTMLAttributes<HTMLDivElement> {
	isAuthenticated: boolean;
	isLoading: boolean;
	hasAccess: boolean;
	isAdmin: boolean;
}
const NavHeader = ({ isAuthenticated, isLoading, hasAccess, isAdmin }: INavHeaderProps) => {
	return (
		<Navbar bg="primary" variant="dark" expand="md" fixed="top" className="d-print-none">
			<Container fluid>
				<Link to="/" className="navbar-brand d-flex align-items-center gap-1">
					<BrandImage src={Logo} className="d-inline-block" />
					<BrandTitle>{DEFAULTS.pageTitle}</BrandTitle>
				</Link>
				<Navbar.Toggle area-controls="navbarSupportedContent" />
				{hasAccess && (
					<Navbar.Collapse id="navbarSupportedContent">
						<Nav className="flex-grow-1" id="navStartPortal">
							<NavLink to={`/${DEFAULTS.routes.project}/${DEFAULTS.routes.search}`} className="nav-link">
								<TextIcon Icon={BsFolder} size="lg">
									Проекти
								</TextIcon>
							</NavLink>
							{/* <Nav.Link target="_blank" href="https://fsmgp.gitbook.io/moria/" className="text-nowrap ms-auto">
								<TextIcon inline Icon={BsQuestionCircle} size="lg">
									<span className="d-md-none d-xl-inline">Допомога</span>
								</TextIcon>
							</Nav.Link> */}
							{/* <div id="navStartPortal" /> */}
						</Nav>
						<Nav id="navEndPortal">
							{isAdmin && (
								<NavDropdown
									title={
										<TextIcon inline Icon={BsShieldLock} size="lg" className="mt-n1">
											<span className="d-md-none d-lg-inline">Адміністрування</span>
										</TextIcon>
									}
									id="ddAdmin"
									className="order-1"
								>
									<NavDropdown.Item as={Link} to={`/${DEFAULTS.routes.manage}/${DEFAULTS.routes.users}`}>
										<TextIcon Icon={BsPersonFill}>Користувачі</TextIcon>
									</NavDropdown.Item>
									<NavDropdown.Item as={Link} to={`/${DEFAULTS.routes.manage}/${DEFAULTS.routes.units}`}>
										<TextIcon Icon={BsBoxes}>Підрозділи</TextIcon>
									</NavDropdown.Item>
								</NavDropdown>
							)}
						</Nav>
					</Navbar.Collapse>
				)}
				<Nav className="ms-auto">{isLoading || isAuthenticated ? <LogoutButton /> : <LoginButton />}</Nav>
			</Container>
		</Navbar>
	);
};

export default NavHeader;

const BrandImage = styled.img`
	width: 40px;
	height: 40px;
	margin: -10px 0 -8px;
`;

const BrandTitle = styled.span`
	margin-top: -2px;
`;
