import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { TProjectContext } from '../../interfaces/projects';
import { GridAutoFill } from '../../styledComponents';
import { useEntitySearchQuery } from '../services/entity';
import EntityCard from './EntityCard';

interface ISimilarEntitiesProps extends React.AllHTMLAttributes<HTMLDivElement> {
	term: string;
}
const SimilarEntities = ({ term, className }: ISimilarEntitiesProps) => {
	const { data: similarEntities } = useEntitySearchQuery(
		{ term, pId: '', count: '24' },
		{ skip: term.trim().length < 3 }
	);
	const { projectEntitiesIds } = useOutletContext<TProjectContext>();

	if (!similarEntities || similarEntities.length === 0 || term.trim().length < 3) return null;
	return (
		<section className={className}>
			<h6 className="mt-1">Об'єкти зі схожими назвами</h6>
			<GridAutoFill $baseSize="200px">
				{similarEntities.map((entity) => (
					<EntityCard key={entity.id} entity={entity} inProject={projectEntitiesIds.has(entity.id)} simple />
				))}
			</GridAutoFill>
		</section>
	);
};

export default SimilarEntities;

// const SmallCard = styled(EntityCard)`
// 	flex: 0 0 200px;
// `;
