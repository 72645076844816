import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import appReducer from './features/app/appSlice';
import projectReducer from './features/projects/projectSlice';
import { photonApi } from './features/services/photonApi';

const store = configureStore({
	reducer: {
		app: appReducer,
		project: projectReducer,
		[photonApi.reducerPath]: photonApi.reducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(photonApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppDispatch: () => AppDispatch = useDispatch;

export const selectAppStore = (state: RootState) => state.app;
export const selectProjectStore = (state: RootState) => state.project;

export default store;
