import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { IDictionaries } from '../../interfaces/dict';
import { QUERY_TAGS } from '../../interfaces/common';
import { IDictionaries, IUserRights, TUnitDictionary } from '../../interfaces/dicts';
import { RootState } from '../../store';

export type TIssueType = 'b' | 'e' | 'h' | 'q';
export interface IIssue {
	title: string;
	body?: string;
	location: string;
	type: TIssueType;
}

export const photonApi = createApi({
	reducerPath: 'photonApi',
	baseQuery: fetchBaseQuery({
		baseUrl: 'api/',
		prepareHeaders: (headers, { getState }) => {
			const accessToken = (getState() as RootState).app.accessToken;
			if (accessToken) {
				headers.set('Authorization', `Bearer ${accessToken}`);
			}
			return headers;
		},
	}),
	tagTypes: [QUERY_TAGS.user, QUERY_TAGS.unit],
	refetchOnMountOrArgChange: 300,
	endpoints: (builder) => ({
		dictionaries: builder.query<IDictionaries, void>({
			query: () => 'dicts',
		}),
		units: builder.query<TUnitDictionary, void>({
			query: () => 'dicts/units',
			providesTags: [{ type: QUERY_TAGS.unit, id: 'list' }],
		}),
		userRights: builder.query<IUserRights, void>({
			query: () => 'dicts/user',
		}),
		// newIssue: builder.mutation<any, IIssue>({
		// 	query: (body) => ({
		// 		url: 'edit/issue',
		// 		method: DEFAULTS.httpMethod.post,
		// 		body,
		// 	}),
		// }),
		// appVersion: builder.query<{ appVersion: string }, void>({
		// 	query: () => '../public/api/app_version',
		// }),
	}),
});

export const { useDictionariesQuery, useUnitsQuery, useUserRightsQuery } = photonApi;
