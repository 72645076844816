import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import WithDictionaries, { IWithDictionaries } from './hoc/withDictionaries';

interface ICountryFlagProps extends React.AllHTMLAttributes<HTMLDivElement> {
	digram: string;
	isSquare?: boolean;
}
const CountryFlag = React.memo(({ digram, title, isSquare = false, className }: ICountryFlagProps) => (
	<FlagContainer
		className={classNames(className, 'flex-shrink-0 country-flag border', isSquare && 'fis', `fi fi-${digram}`)}
		title={title}
	/>
));

interface INatoCountryFlagProps extends IWithDictionaries, React.AllHTMLAttributes<HTMLDivElement> {
	trigram?: string | null;
	isSquare?: boolean;
	withTitle?: boolean;
}
const NatoCountryFlag = React.memo(
	({
		countries: { entities: countriesById },
		trigram,
		isSquare = false,
		className,
		withTitle = false,
	}: INatoCountryFlagProps) => {
		const country = trigram ? countriesById[trigram] : undefined;
		let digram = country ? country.digram.toLowerCase() : 'un';
		return withTitle ? (
			<NatoContainer className={className} title={country?.title}>
				<NatoFlag digram={digram} isSquare={isSquare} />
				<span className="ms-1">{country?.title}</span>
			</NatoContainer>
		) : (
			<CountryFlag digram={digram} isSquare={isSquare} className={className} title={`${trigram} — ${country?.title}`} />
		);
	}
);

export default WithDictionaries(NatoCountryFlag);

const NatoContainer = styled.span`
	--bs-body-line-height: 1;
`;

const NatoFlag = styled(CountryFlag)`
	margin-top: -0.1em;
`;

const FlagContainer = styled.span`
	--bs-border-color: rgba(0, 0, 0, 0.25);
`;
