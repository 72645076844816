import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { Card } from 'react-bootstrap';
import { BsFillPatchCheckFill, BsPencilFill, BsShieldFillCheck, BsTools } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { DEFAULTS } from '../../common';
import { IDictionary } from '../../interfaces/common';
import { IUnitDic } from '../../interfaces/dicts';
import { IRecentProject } from '../../interfaces/projects';
import ProjectContentStatistic from './ProjectContentStatistic';
import ProjectEntityList from './projectEntityList';

interface IRecentProjectProps extends React.AllHTMLAttributes<HTMLDivElement> {
	project: IRecentProject;
	unitsById: IDictionary<number, IUnitDic>;
	// Якщо задано serialNo, то ми знаходимось на сторінці пошуку проектів
	serialNo?: number;
}
const RecentProject = ({ project, unitsById, serialNo }: IRecentProjectProps) => {
	const {
		project_id,
		start_date,
		end_date,
		is_creator,
		is_manager,
		is_editor,
		// title,
		initiator_id,
		// unit_id,
		creator_email,
		project_duration,
		entities_count,
		relationships_count,
		targets,
	} = project;
	const isActive = end_date === null;
	const startDate = dayjs.utc(start_date).local();
	const endDate = dayjs.utc(end_date).local();
	const isOverdue = isActive && DEFAULTS.maxProjectDuration < dayjs().diff(startDate, 'd');
	const status = is_creator
		? isActive
			? isOverdue
				? 'danger'
				: 'warning'
			: 'success'
		: is_manager
		? isOverdue
			? 'danger'
			: 'primary'
		: is_editor
		? 'secondary'
		: 'light';
	const patchStatus = isActive ? (isOverdue ? 'danger' : 'warning') : 'success';

	return (
		<Card
			className={classNames(
				'position-relative shadow',
				(!serialNo || isOverdue) && `project-card project-card_${status}`,
				!serialNo && !is_creator && !isActive && 'opacity-50'
			)}
		>
			<Card.Header className="hstack gap-2">
				{serialNo && <span className="badge text-bg-secondary mt-n1">{serialNo}</span>}
				<div className="min-width-0 text-truncate">
					Автор:<span className="fw-bolder ms-1">{creator_email}</span> • Ініціатор:
					<span className="fw-bolder ms-1">{unitsById[initiator_id]?.code}</span>
				</div>
				<ProjectContentStatistic
					entitiesCount={entities_count}
					relationshipsCount={relationships_count}
					className="ms-auto"
				/>
			</Card.Header>
			<Link to={`/project/${project_id}`} className="stretched-link" />
			<ProjectEntityList className="card-body" entities={targets} />
			<Card.Footer className="hstack justify-content-between gap-1">
				<div className="min-width-0 text-truncate">
					{startDate.format('L')} — {isActive ? '...' : endDate.format('L')}
					<span className="ms-1">(тривалість: {project_duration} д.)</span>
				</div>
				<div className="text-nowrap hstack gap-1 z-1">
					{is_editor && <BsPencilFill title="Редагування вмісту проекту" />}
					{is_creator && <BsTools title="Редагування установчих даних проекту" />}
					{is_manager && <BsShieldFillCheck title="Контролер" />}
					{serialNo && <BsFillPatchCheckFill className={`text-${patchStatus} white-halo`} title="Статус проекту" />}
				</div>
			</Card.Footer>
		</Card>
	);
};

export default RecentProject;
