import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import {
	BsArrowLeft,
	BsArrowLeftRight,
	BsArrowRightCircle,
	BsCopy,
	BsPencilSquare,
	BsShieldLock,
	BsTrash,
} from 'react-icons/bs';
import { Navigate, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { showError, showPromiseConfirm } from '../../alerts';
import { DEFAULTS, DELETION_WARNING, EXIT_CONFIRMATION, TOrigin } from '../../common';
import CommonSelector from '../../components/CommonSelector';
import DialogFooter from '../../components/DialogFooter';
import { FixedDotSpinner } from '../../components/DotSpinner';
import FetchError from '../../components/FetchError';
import TextIcon from '../../components/TextIcon';
import DescriptionControl, { IActionButtonData } from '../../components/controls/DescriptionControl';
import EntitySelector from '../../components/controls/EntitySelector';
import WithDictionaries, { IWithDictionaries } from '../../components/hoc/withDictionaries';
import { useFormFields } from '../../hooks';
import { ITextArrayDataOptions } from '../../interfaces/common';
import { IEntityBase } from '../../interfaces/entities';
import { TProjectContext } from '../../interfaces/projects';
import { IRelationshipBase, IRelationshipObject } from '../../interfaces/relationship';
import { removeEdge, upsertEdge } from '../../schemeUtils';
import { moveControlToScreenCenter } from '../../scrolls';
import { selectAppStore, useAppDispatch, useAppSelector } from '../../store';
import { getDataForSave } from '../../utils';
import DangerousListItem from '../dangerous/DangerousListItem';
import DangerousZone from '../dangerous/DangerousZone';
import { changeNetworkStateTs } from '../projects/projectSlice';
import { useEditRelationshipMutation, useRelationshipQuery } from '../services/relationship';
import RelationshipImageList from './RelationshipImageList';

const DEFAULT_RELATIONSHIP: IRelationshipObject = {
	relationshipBase: {
		id: '0',
		entity_id_1: 0,
		entity_id_2: 0,
		relationship_type_id: 1,
		created_at: '',
		creator_id: 0,
		unit_id: 0,
		description: '',
	},
	periods: [],
	editable: true,
	images: [],
};
interface IRelationshipDialogProps extends IWithDictionaries, React.AllHTMLAttributes<HTMLDivElement> {}
const RelationshipDialog = ({ relationshipTypes }: IRelationshipDialogProps) => {
	const { relationshipId = '0' } = useParams();
	const inserting = relationshipId === '0';
	const idIsValid = /^\d+$/.test(relationshipId);
	const navigate = useNavigate();
	const { projectEntitiesIds, projectId, userProjectRights } = useOutletContext<TProjectContext>();
	const { state: referer } = useLocation();
	const {
		data: relationship,
		isFetching,
		error,
	} = useRelationshipQuery(relationshipId, { skip: relationshipId === '0' || !idIsValid });
	const [updateRelationship, updateRelationshipResult] = useEditRelationshipMutation();
	const [initialData, setInitialData] = useState<IRelationshipBase>(() => ({
		...DEFAULT_RELATIONSHIP.relationshipBase,
		entity_id_1: (referer as TOrigin)?.entityId ?? 0,
	}));
	const { formFields, createChangeHandler, createSetHandler, modified, setFormFields } = useFormFields(initialData);
	const dispatch = useAppDispatch();
	const { userRights, isAdmin } = useAppSelector(selectAppStore);
	const [imagesState, setImagesState] = useState<ITextArrayDataOptions>(() => ({
		modified: false,
		selected: relationship?.images || [],
	}));
	const [adminEditing, setAdminEditing] = useState(false);
	const [actionButtonsData, setActionsButtonData] = useState<(IActionButtonData | null)[]>(() => [null, null]);

	const editingDisabled =
		isAdmin && adminEditing
			? false
			: !userRights?.isEditor || !userProjectRights?.is_editor || (inserting ? false : !relationship?.editable);
	const disabled = editingDisabled || updateRelationshipResult.isLoading;

	useEffect(() => {
		if (!relationship) return;
		const { relationshipBase, images = [] } = relationship;
		setInitialData(relationshipBase);
		setImagesState({ modified: false, selected: images });
	}, [relationship]);

	const someModified = inserting || modified || imagesState?.modified;

	const closeDialog = useCallback(() => {
		const state = referer as TOrigin;
		if (state?.origin === 'basic') navigate(-1);
		else navigate('..');
	}, [referer, navigate]);

	const handleClose = useCallback(async () => {
		if (someModified && !(await showPromiseConfirm(EXIT_CONFIRMATION))) return;
		closeDialog();
	}, [someModified, closeDialog]);

	const handleBack = useCallback(async () => {
		if (someModified && !(await showPromiseConfirm(EXIT_CONFIRMATION))) return;
		navigate(-1);
		const state = referer as TOrigin;
		if (state?.controlId)
			setTimeout(() => {
				if (state?.controlId) moveControlToScreenCenter(document.getElementById(state.controlId));
			}, 200);
	}, [someModified, navigate, referer]);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();

		// if (inEditing) return showError('Завершіть, будь ласка, редагування запису');

		const dataToSave = getDataForSave(inserting ? undefined : initialData, formFields, ['id']);
		// console.log(dataToSave);
		updateRelationship({
			...dataToSave,
			projectId,
			method: inserting ? DEFAULTS.httpMethod.post : DEFAULTS.httpMethod.put,
			images: imagesState.selected,
		});
	};

	useEffect(() => {
		if (!updateRelationshipResult) return;
		const { isError, error, isSuccess, data } = updateRelationshipResult;
		if (isSuccess) {
			/**
			 * При оновленні простіше видалити ребро та створити його заново,
			 * тому що інакше треба перемінити взагалі всі властивості ребра.
			 */
			// const updatedRelationshipId = data.action !== 'delete' ? data.relationshipBase.id : data.id;
			// removeEdge(updatedRelationshipId);
			if (data.action !== 'delete') {
				const { relationshipBase, entity1, entity2 } = data;
				upsertEdge(relationshipBase, entity1?.entityBase, entity2?.entityBase);
			} else {
				removeEdge(data.id);
			}
			dispatch(changeNetworkStateTs());
			const state = referer as TOrigin;
			if (state?.origin === 'search') navigate(-1);
			else closeDialog();
		}
		if (isError) showError(<pre>{JSON.stringify(error, undefined, '  ')}</pre>, DEFAULTS.updateErrorText);
	}, [updateRelationshipResult]); // eslint-disable-line

	const onSwapEntities = () => {
		setFormFields({ ...formFields, entity_id_1: formFields.entity_id_2, entity_id_2: formFields.entity_id_1 });
	};

	const onDeleteClick = useCallback(async () => {
		if (!(await showPromiseConfirm("Ви впевнені, що хочете видалити зв'язок з проекту та взагалі?"))) return;
		await updateRelationship({ id: relationshipId, projectId, method: DEFAULTS.httpMethod.delete });
	}, [relationshipId, projectId, updateRelationship]);

	const onCopyClick = useCallback(() => {
		setFormFields((f) => ({ ...f, id: '0' }));
		navigate(`../${DEFAULTS.routes.relationship}/0`);
	}, [navigate, setFormFields]);

	const onEditorEditClick = useCallback(async () => {
		if (
			!(await showPromiseConfirm(
				"Ви впевнені, що бажаєте використати права адміністратора для редагування даного зв'язку?"
			))
		)
			return;
		setAdminEditing(true);
	}, []);

	useEffect(() => {
		setAdminEditing(false);
	}, [relationshipId]);

	const onEntitySelector = useCallback((fieldName: string, entity: IEntityBase | null) => {
		const index = fieldName === 'entity_id_1' ? 0 : 1;
		setActionsButtonData((data) => {
			const newData = [...data];
			if (newData[index]?.title === entity?.title) return data;
			newData[index] = entity
				? { contentAfter: entity.title, title: entity.title, caption: '📝 ' + entity.title }
				: null;
			return newData;
		});
	}, []);

	if (!idIsValid) return <Navigate to=".." replace />;

	return (
		<Modal show centered size="xl" fullscreen="xl-down" onHide={handleClose} dialogClassName="modal-90w" scrollable>
			<Modal.Header closeButton className="align-items-center gap-1">
				{(referer as TOrigin)?.origin === 'search' && (
					<Button variant="secondary" onClick={handleBack} type="button" size="sm">
						<TextIcon Icon={BsArrowLeft}>Назад</TextIcon>
					</Button>
				)}
				<Modal.Title>
					<TextIcon Icon={BsPencilSquare} className="mt-n1 me-1" />
					{inserting ? <>Створення</> : relationship?.editable ? <>Редагування</> : <>Перегляд</>} зв'язку
					{relationship && (
						<span className="text-muted ms-2 text-small">
							(створено {dayjs.utc(formFields.created_at).local().format('L LT')})
						</span>
					)}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="vh-75 vstack">
				{error && <FetchError error={error} />}
				{isFetching && <FixedDotSpinner>Завантаження інформації про зв'язок</FixedDotSpinner>}
				<Form onSubmit={onSubmit} id="fmRelationship" className="row g-2">
					<EntitySelector
						initialEntityId={formFields.entity_id_1}
						fieldName="entity_id_1"
						title="Початковий об'єкт"
						disabled={disabled}
						className="col"
						onEntityChange={createSetHandler('entity_id_1', 'number')}
						focusOnShow={true}
						projectEntitiesIds={projectEntitiesIds}
						withAddButton={!disabled}
						onEntity={editingDisabled ? undefined : onEntitySelector}
					/>
					<Col xs={3} className="position-relative">
						<CommonSelector
							onItemChange={createSetHandler('relationship_type_id', 'number')}
							selectedId={formFields.relationship_type_id}
							allItems={relationshipTypes.entities}
							label="Тип зв'язку"
							required
							fieldName="relationship_type_id"
							labelName="title"
							valueName="id"
							disabled={disabled}
						/>
						<Button
							variant="link"
							className="position-absolute top-0 start-50 translate-middle-x p-0 border-0"
							title="Змінити порядок об'єктів"
							onClick={onSwapEntities}
							tabIndex={-1}
							disabled={disabled}
						>
							<TextIcon
								Icon={
									relationshipTypes.entities[formFields.relationship_type_id]?.directional
										? BsArrowRightCircle
										: BsArrowLeftRight
								}
								className="icon-lg"
							/>
						</Button>
					</Col>
					<EntitySelector
						initialEntityId={formFields.entity_id_2}
						fieldName="entity_id_2"
						title="Кінцевий об'єкт"
						disabled={disabled}
						className="col"
						onEntityChange={createSetHandler('entity_id_2', 'number')}
						focusOnShow={false}
						projectEntitiesIds={projectEntitiesIds}
						withAddButton={!disabled}
						onEntity={editingDisabled ? undefined : onEntitySelector}
					/>
				</Form>
				<DescriptionControl
					controlId="rDescription"
					controlClassName="mt-2 flex-grow-1"
					value={formFields.description}
					onChange={createChangeHandler('description')}
					placeholder="Введіть опис зв'язку"
					required
					form="fmRelationship"
					className="flex-grow-1"
					disabled={editingDisabled}
					onValueChange={createSetHandler('description')}
					actionButtonsData={actionButtonsData}
				/>
				{(!editingDisabled || imagesState.selected.length !== 0) && (
					<RelationshipImageList
						initialImages={relationship?.images}
						className="mt-3"
						disabled={editingDisabled}
						onChange={setImagesState}
						isLoading={updateRelationshipResult.isLoading}
						inserting={inserting}
					/>
				)}
				{!inserting && relationship?.editable && (
					<DangerousZone className="mt-2">
						<DangerousListItem onClick={onDeleteClick} title="Видалити зв'язок." description={DELETION_WARNING}>
							<TextIcon Icon={BsTrash}>Видалити</TextIcon>
						</DangerousListItem>
					</DangerousZone>
				)}
			</Modal.Body>
			<DialogFooter
				formId="fmRelationship"
				entityId={inserting ? undefined : relationshipId}
				disabled={!someModified || updateRelationshipResult.isLoading}
				isLoading={updateRelationshipResult.isLoading}
				onClose={handleClose}
			>
				{relationship && (
					<>
						{isAdmin && editingDisabled && (
							<Button variant="outline-danger" onClick={onEditorEditClick}>
								<TextIcon inline Icon={BsShieldLock} size="lg" className="mt-n1">
									Редагувати
								</TextIcon>
							</Button>
						)}
						{!inserting && userRights?.isEditor && userProjectRights?.is_editor && (
							<Button variant="outline-secondary" onClick={onCopyClick}>
								<TextIcon Icon={BsCopy}>Скопіювати зв'язок</TextIcon>
							</Button>
						)}
					</>
				)}
			</DialogFooter>
		</Modal>
	);
};

export default WithDictionaries(RelationshipDialog);
