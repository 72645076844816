import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { TOrigin } from '../../common';
import { FixedDotSpinner } from '../../components/DotSpinner';
import FetchError from '../../components/FetchError';
import HeaderWithImage from '../../components/HeaderWithImage';
import ImgVoid from '../../img/undraw_void_-3-ggu.svg';
import { TProjectContext } from '../../interfaces/projects';
import { selectProjectStore, useAppSelector } from '../../store';
import { GridAutoFill } from '../../styledComponents';
import { useEntitySearchQuery } from '../services/entity';
import EntityCard from './EntityCard';
import EntitySearchPanel from './EntitySearchPanel';

// interface IEntitySearchDialogProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const EntitySearchDialog = () => {
	const {
		entitySearchParams: { term, inProjectOnly },
	} = useAppSelector(selectProjectStore);
	const { projectEntitiesIds } = useOutletContext<TProjectContext>();
	const { pId = '0' } = useParams<{ pId: string }>();
	// const projectId = parseInt(pId || '-');
	const { data, error, isFetching } = useEntitySearchQuery(
		{ term, pId: inProjectOnly ? pId : '', count: '36' },
		{ skip: !term.trim() }
	);
	const navigate = useNavigate();
	const { state: referer } = useLocation();

	const handleClose = () => {
		if ((referer as TOrigin)?.origin) navigate(-1);
		else navigate('..');
	};

	return (
		<Modal show centered size="xl" fullscreen="xl-down" onHide={handleClose} scrollable>
			<Modal.Header closeButton>
				<Modal.Title>Пошук об'єктів</Modal.Title>
			</Modal.Header>
			<Modal.Body className="vh-75">
				{error && <FetchError error={error} />}
				<EntitySearchPanel id="fmEntitySearchDialog" noNavigate focusOnInit />
				{data && (
					<GridAutoFill className="mt-3 align-items-start" $baseSize="300px">
						{data.map((entity) => (
							<EntityCard key={entity.id} entity={entity} inProject={projectEntitiesIds.has(entity.id)} />
						))}
					</GridAutoFill>
				)}
				{data?.length === 0 && (
					<HeaderWithImage
						imgSrc={ImgVoid}
						// alt="Зображення відсутності результатів"
						headerClassName="text-info"
						title="За Вашим пошуковим запитом нічого не знайдено"
						// className="col"
					/>
				)}
				{isFetching && <FixedDotSpinner>Пошук об'єктів</FixedDotSpinner>}
			</Modal.Body>
		</Modal>
	);
};

export default EntitySearchDialog;
