import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import React, { Suspense, useEffect, useRef } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import ErrorHandler from '../../components/ErrorHandler';
import Layout from '../../components/Layout';
import Preloader from '../../components/Preloader';
import SplashScreen from '../../components/SplashScreen';
import AccessTokenUpdater from './AccessTokenUpdater';
// import AppVersionChecker from './AppVersionChecker';
import { skipToken } from '@reduxjs/toolkit/query';
import { DEFAULTS } from '../../common';
import { RootState } from '../../store';
import EntityDialog from '../entities/EntityDialog';
import EntitySearchDialog from '../entities/EntitySearchDialog';
import FullEntityDialog from '../entities/FullEntityDialod';
import UnitsManageLayout from '../manage/units/UnitsManageLayout';
import UsersManageLayout from '../manage/users/UsersManageLayout';
import ProjectSummary from '../projectSummary/ProjectSummary';
import ProjectDialog from '../projects/ProjectDialog';
import RecentProjectList from '../projects/recentProjectList';
import SearchProjectLayout from '../projects/search/SearchProjectLayout';
import RelationshipDialog from '../relationships/RelationshipDialog';
import { useUserRightsQuery } from '../services/photonApi';
import { changePermissions, changeUserRights } from './appSlice';

const LazyPageNotFound = React.lazy(() => import('../../components/PageNotFound'));
const LazyManager = React.lazy(() => import('../manage/Manager'));
const LazyProjectLayout = React.lazy(() => import('../projects/ProjectLayout'));

const router = createHashRouter([
	{
		path: '/',
		element: <Layout />,
		errorElement: <ErrorHandler />,
		children: [
			{
				path: DEFAULTS.routes.manage,
				element: (
					<Suspense fallback={<Preloader fixed />}>
						<LazyManager />
					</Suspense>
				),
				children: [
					{
						path: DEFAULTS.routes.users,
						element: <UsersManageLayout />,
					},
					{
						path: DEFAULTS.routes.units,
						element: <UnitsManageLayout />,
					},
				],
			},
			{
				path: DEFAULTS.routes.project,
				children: [
					{ path: DEFAULTS.routes.search, element: <SearchProjectLayout /> },
					{
						path: ':pId',
						element: (
							<Suspense fallback={<Preloader />}>
								<LazyProjectLayout />
							</Suspense>
						),

						children: [
							{
								path: `${DEFAULTS.routes.entityEdit}/:eId`,
								element: <EntityDialog />,
							},
							{
								path: `${DEFAULTS.routes.entity}/:eId`,
								element: <FullEntityDialog />,
							},
							{
								path: `${DEFAULTS.routes.relationship}/:relationshipId`,
								element: <RelationshipDialog />,
							},
							{
								path: DEFAULTS.routes.search,
								element: <EntitySearchDialog />,
							},
							{
								path: DEFAULTS.routes.data,
								element: <ProjectDialog />,
							},
							{
								path: DEFAULTS.routes.summary,
								element: <ProjectSummary />,
							},
						],
					},
					// {
					// 	path: '*',
					// 	element: <Navigate to="../search" replace />,
					// },
				],
			},
			{
				// path: '/',
				index: true,
				element: <RecentProjectList />,
			},
			{
				path: '*',
				element: (
					<Suspense fallback={<Preloader />}>
						<LazyPageNotFound />
					</Suspense>
				),
			},
		],
	},
]);

interface IAppProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const App = ({ changePermissions, changeUserRights, accessToken }: IAppProps) => {
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
	const oldHash = useRef(window.location.hash);
	const { data: userRights } = useUserRightsQuery(accessToken ? undefined : skipToken);

	useEffect(() => {
		const updatePermissions = async (isAuthenticated: boolean) => {
			const accessToken = isAuthenticated ? await getAccessTokenSilently() : undefined;
			changePermissions(accessToken ? (jwtDecode(accessToken) as { scope: string }).scope.split(' ') : undefined);
		};

		updatePermissions(isAuthenticated);
	}, [isAuthenticated, getAccessTokenSilently, changePermissions]);

	useEffect(() => {
		if (!userRights) return;
		changeUserRights(userRights);
	}, [userRights, changeUserRights]);

	useEffect(() => {
		const newHash = window.location.hash;

		// Пустий хеш виникає з якоїсь причини після входу в обліковий запис:
		// пошук здійснюється, а хеш очищується. В цьому разі встановлюємо збережений хеш.
		if (!newHash) {
			if (isAuthenticated && oldHash.current) window.location.hash = oldHash.current;
		} else {
			if (oldHash.current !== newHash) oldHash.current = newHash;
		}
	}, [isAuthenticated]);

	if (!(isAuthenticated || isLoading)) return <SplashScreen />;

	return (
		<>
			<RouterProvider router={router} />
			<AccessTokenUpdater />
			{/* <AppVersionChecker /> */}
		</>
	);
};

const mapState = (state: RootState) => ({
	accessToken: state.app.accessToken,
});

const mapDispatch = { changePermissions, changeUserRights };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
