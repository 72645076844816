import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

interface IHeaderWithImageProps extends React.AllHTMLAttributes<HTMLDivElement> {
	imgSrc: string;
	imgClassName?: string;
	alt?: string;
	headerClassName?: string;
	headerLevel?: number;
	imageFirst?: boolean;
	small?: boolean;
	gap?: number;
}
const HeaderWithImage = ({
	className,
	title,
	imgSrc,
	alt,
	headerClassName,
	headerLevel = 3,
	children,
	imgClassName = 'w-25',
	imageFirst = false,
	small = false,
	gap = 2,
}: IHeaderWithImageProps) => {
	return (
		<div className={classNames('d-flex flex-column align-items-center', `gap-${gap}`, className)}>
			{title && (
				<div className={classNames(headerClassName, `h${headerLevel}`, imageFirst && 'order-1', 'text-center')}>
					{title}
				</div>
			)}
			<HeaderImage src={imgSrc} className={imgClassName} alt={alt || title} $small={small} />
			{children}
		</div>
	);
};

export default HeaderWithImage;

const HeaderImage = styled.img<{ $small: boolean }>`
	max-width: ${(props) => (props.$small ? '150px' : '250px')};
`;
