import classNames from 'classnames';
import React from 'react';
import { IEntityBase } from '../interfaces/entities';
import { getEntityLogoSrc } from '../utils';
import EntityTypeImage from './EntityTypeImage';

interface IEntityImageProps extends React.AllHTMLAttributes<HTMLDivElement> {
	entity: Pick<IEntityBase, 'entity_type' | 'logo' | 'title'>;
}
const EntityImage = ({ entity: { entity_type, logo, title }, className }: IEntityImageProps) =>
	logo ? (
		<img src={getEntityLogoSrc(logo)} className={classNames(className, 'entity-icon')} alt={title} />
	) : (
		<EntityTypeImage entityTypeId={entity_type} className={className} />
	);

export default EntityImage;
