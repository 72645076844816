import { IEntity, TNumberOrNull } from './common';

export interface ICountryDic {
	digram: string;
	trigram: string;
	title: string;
}
export type TCountryDictionary = IEntity<string, ICountryDic>;
export interface IEntityTypeDic {
	id: number;
	title: string;
}
export type TEntityTypeDictionary = IEntity<number, IEntityTypeDic>;
interface IRelationshipTypeCommon {
	id: number;
	title: string;
	directional: boolean;
}
// interface IRelationshipTypeBase extends IRelationshipTypeCommon {
// 	back_title: string;
// }
export interface IRelationshipTypeDic extends IRelationshipTypeCommon {
	backTitle: string;
}
export type TRelationshipTypeDictionary = IEntity<number, IRelationshipTypeDic>;

export interface IDictionaries {
	countries: TCountryDictionary;
	entityTypes: TEntityTypeDictionary;
	relationshipTypes: TRelationshipTypeDictionary;
}

export const ENTITY_STATE_STUB = { ids: [], entities: {} };

export interface IUnitDic {
	id: number;
	code: string;
}
export type TUnitDictionary = IEntity<number, IUnitDic>;

export interface IUserRights {
	isEditor: boolean;
	unitId: TNumberOrNull;
}
