import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { showError, showPromiseConfirm } from '../../alerts';
import { useArray } from '../../hooks';
import { ITextArrayDataOptions, TEntityId } from '../../interfaces/common';
import { getControlData } from '../../utils';
import EditButtonsGroup from './EditButtonsGroup';

interface ITextInputsGroupProps {
	initialData?: TEntityId<string>[];
	itemClassName?: string;
	onChange: (data: ITextArrayDataOptions) => void;
	/**
	 * Визначає, чи дозволено редагування в даний момент
	 */
	disabled?: boolean;
	setInEditing: (newInEditing: boolean) => void;
	/**
	 * Визначає, чи можуть дані редагуватись в принципі
	 */
	editable?: boolean;
	maxLen?: number;
}
const TextInputsGroup = ({
	initialData,
	onChange,
	itemClassName,
	disabled,
	setInEditing,
	editable,
	maxLen = 150,
}: ITextInputsGroupProps) => {
	const { data, toggleItem, setData, modified } = useArray(initialData);
	const [newItemIndex, setNewItemIndex] = useState(initialData?.length ?? 0);
	const [editingIndex, setEditingIndex] = useState(newItemIndex);
	const refSelectedInput = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setNewItemIndex(data.length);
		setEditingIndex(data.length);
	}, [data.length]);

	// useEffect(() => {
	// 	setInEditing(newItemIndex !== editingIndex);
	// }, [newItemIndex, editingIndex, setInEditing]);

	useEffect(() => {
		setData(initialData);
	}, [initialData]); // eslint-disable-line

	useEffect(() => {
		onChange({ modified, selected: data });
	}, [modified, data]); // eslint-disable-line

	useEffect(() => {
		focusEditing();
	}, [editingIndex]); // eslint-disable-line

	const focusEditing = useCallback(
		() =>
			setTimeout(() => {
				refSelectedInput.current?.focus();
				setInEditing(refSelectedInput.current?.value.trim() !== '');
			}, 50),
		[setInEditing]
	);

	const onControlClick = async (event: React.MouseEvent<HTMLDivElement>) => {
		const action = getControlData(event.target, '.btn-action', 'action');
		const strIndex = getControlData(event.target, '.text-input_group', 'index') as string;
		if (!action || !strIndex) return;
		const index = parseInt(strIndex, 10);
		switch (action) {
			case 'edit':
				setEditingIndex(index);
				break;
			case 'delete':
				const oldTitle = data[index];
				if (!(await showPromiseConfirm(`Ви впевнені, що бажаєте видалити назву "${oldTitle}?"`))) return;
				toggleItem(oldTitle);
				break;
		}
	};

	const onItemSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		event.stopPropagation();
		const form = event.target as HTMLFormElement;
		const formData = new FormData(form);
		const newTitle = formData.get('title')?.toString().trim() ?? '';
		if (newTitle === '') return showError('Назва не може бути порожньою');
		if (data.includes(newTitle)) return showError('Така назва вже існує');
		if (editingIndex !== newItemIndex) {
			if (data[editingIndex] !== newTitle)
				setData(data.map((title, index) => (index !== editingIndex ? title : newTitle)));
			setEditingIndex(newItemIndex);
		} else {
			setData([...data, newTitle]);
		}
	};

	const onItemReset = () => {
		setEditingIndex(newItemIndex);
		focusEditing();
	};

	const onNewItemChange = useCallback(
		async (event: React.ChangeEvent<HTMLInputElement>) => {
			if (editingIndex !== newItemIndex) return;
			setInEditing(event.target.value.trim() !== '');
		},
		[editingIndex, newItemIndex, setInEditing]
	);

	return (
		<InputsGroupContainer className="gap-1" onClick={onControlClick}>
			{(editable ? [...data, ''] : data).map((title, index) => (
				<React.Fragment key={`${title}_${index}`}>
					{/* {index === newItemIndex && (
							<Form.Label className={classNames(data.length !== 0 && 'mt-1 mb-0')}>Нова назва</Form.Label>
						)} */}
					<Form
						data-index={index}
						className="input-group text-input_group z-0"
						onSubmit={onItemSubmit}
						onReset={onItemReset}
					>
						<Form.Control
							// id={idTemplate + '_' + index}
							type="text"
							defaultValue={title}
							className={itemClassName}
							disabled={editingIndex !== index || disabled}
							ref={editingIndex === index ? refSelectedInput : undefined}
							name="title"
							placeholder={
								title !== '' ? `Старе значення: "${title}"` : 'Введіть назву та натисніть Enter для її збереження'
							}
							size="sm"
							maxLength={maxLen}
							onChange={index !== newItemIndex ? undefined : onNewItemChange}
						/>
						{!disabled && (
							<EditButtonsGroup
								showEditingButtons={editingIndex === index}
								showEditButtons={index !== newItemIndex && index !== editingIndex}
								editButtonsDisabled={editingIndex !== newItemIndex}
								size="sm"
							/>
						)}
					</Form>
				</React.Fragment>
			))}
		</InputsGroupContainer>
	);
};

export default TextInputsGroup;

const InputsGroupContainer = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: repeat(auto-fill, minmax(312px, 1fr));
`;
