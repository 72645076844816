import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import { BsEnvelope } from 'react-icons/bs';
import styled from 'styled-components';
import imgBrand from '../img/brand.webp';
import { TColorTheme } from '../interfaces/common';
import CountryFlag from './CountryFlag';

interface IGeoIp {
	countryCode: string;
	countryCode3: string;
	countryName: string;
	cityName?: string;
	latitude: number;
	longitude: number;
	timeZone: string;
	continentCode: string;
	subdivisionCode: string;
	subdivisionName?: string;
}

interface INavFooterProps extends React.AllHTMLAttributes<HTMLDivElement> {
	hasAccess: boolean;
	colorTheme: TColorTheme;
}
const NavFooter = ({ hasAccess, colorTheme }: INavFooterProps) => {
	const { user } = useAuth0();
	const geoip = user?.geoip as IGeoIp;
	const cityName = [geoip?.cityName, geoip?.subdivisionName].filter(Boolean).join(', ');
	const isLightTheme = colorTheme === 'light';

	return (
		<nav
			className={classNames('navbar mt-auto d-print-none', isLightTheme ? 'bg-light' : 'bg-dark')}
			data-bs-theme={isLightTheme ? undefined : 'dark'}
		>
			{/* <nav className="navbar navbar-light bg-light mt-auto"> */}
			<FooterContainer className="container-fluid flex-wrap justify-content-start">
				<a
					href={`https://${process.env.REACT_APP_DOMAIN_NAME}/`}
					className="d-flex align-items-center text-decoration-none"
				>
					<BrandImage alt="Brand" src={imgBrand} className="me-2" />
					<span className="navbar-text">
						Фундація стратегічного моніторингу
						<br />
						загальних процесів
					</span>
				</a>
				{hasAccess && (
					<span className="navbar-text">
						Зворотній зв'язок <BsEnvelope />
						<br />
						<span className="semi-bold">sashamolodec@gmail.com</span>
					</span>
				)}
				{geoip && (
					<div
						className="navbar-text me-auto d-flex gap-2 align-items-center"
						title={`Login from ${geoip.cityName} ${geoip.countryName}`}
					>
						<UserCountry country={geoip.countryCode} isSquare />
						<div className="text-small1">
							{cityName && (
								<>
									{cityName}
									<br />
								</>
							)}
							{geoip.countryName}
						</div>
					</div>
				)}
			</FooterContainer>
		</nav>
	);
};

const UserCountry = styled(CountryFlag)`
	--country-flag-width: 38px !important;
	--country-flag-height: 38px !important;
	margin-top: -2px;
`;

const BrandImage = styled.img`
	width: 50px;
	height: 50px;
	margin-top: -4px;
`;

const FooterContainer = styled.div`
	margin-right: 36px;
	gap: 0 1.5rem;
`;

export default NavFooter;
