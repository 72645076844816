import React from 'react';
import { useDictionariesQuery } from '../../features/services/photonApi';
import { ENTITY_STATE_STUB, IDictionaries } from '../../interfaces/dicts';

export interface IWithDictionaries extends IDictionaries {}

const WithDictionaries =
	<T extends IWithDictionaries>(WrappedComponent: React.ComponentType<T>) =>
	(props: Omit<T, keyof IWithDictionaries>) => {
		// eslint-disable-next-line
		const { data } = useDictionariesQuery(undefined, {
			refetchOnMountOrArgChange: false,
		});

		return (
			<WrappedComponent
				{...(props as T)}
				countries={data?.countries || ENTITY_STATE_STUB}
				entityTypes={data?.entityTypes || ENTITY_STATE_STUB}
				relationshipTypes={data?.relationshipTypes || ENTITY_STATE_STUB}
			/>
		);
	};

export default WithDictionaries;
