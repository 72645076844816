import React from 'react';
import { Button } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';
import styled from 'styled-components';
import CountryFlag from '../../components/CountryFlag';
import EntityImage from '../../components/EntityImage';
import { IEntityBase } from '../../interfaces/entities';
import { ClampedText } from '../../styledComponents';

interface IProjectEntityItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	entity: IEntityBase;
	countryDigram?: string;
	/**
	 * При наявності даного флага виводить кнопку видалення
	 */
	withActions?: boolean;
}
const ProjectEntityItem = ({ entity, countryDigram, withActions }: IProjectEntityItemProps) => {
	return (
		<EntityContainer className="d-inline-flex rounded-pill white-glass align-items-center gap-2 border border-2">
			<EntityImage entity={entity} className="rounded-pill flex-shrink-0" />
			<ClampedText $linesCount={2} $lineHeight={1.25}>
				{entity.title}
			</ClampedText>
			{withActions && (
				<Button
					variant="link"
					className="p-0 border-0 link-danger btn-action"
					title="Вилучити з цільових об'єктів"
					data-entity-id={entity.id}
				>
					<BsTrash />
				</Button>
			)}
			<EntityFlag isSquare className="rounded-pill" country={countryDigram} />
		</EntityContainer>
	);
};

export default ProjectEntityItem;

const EntityFlag = styled(CountryFlag)`
	--country-flag-width: 2.5em !important;
	--country-flag-height: 2.5em !important;
`;

const EntityContainer = styled.div`
	--bs-border-color: color-mix(in lch, var(--project-card-bg-color, #fff) var(--project-card-mix-percent, 80%), black);
	--entity-size: 2.5em;
`;
