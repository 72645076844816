import { DataSet, Edge, EdgeOptions, IdType, Network, Node, Options } from 'vis-network/standalone';
import { TStringWithUndefined, promiseTimeout } from './common';
import { globalNetworkState } from './features/projects/projectSlice';
import { IDictionary } from './interfaces/common';
import { IRelationshipTypeDic } from './interfaces/dicts';
import { IEntityListItem } from './interfaces/entities';
import { IRelationshipListItem } from './interfaces/relationship';
import { getEntityLogoSrc, loadImage, splitTextByLength } from './utils';

export interface FullNode extends Node {
	is_init: boolean;
	entity_type: number;
}
export interface FullEdge extends Edge {
	relationship_type: number;
}
export type DataSetFullNodes = DataSet<FullNode, 'id'>;
export type DataSetFullEdges = DataSet<FullEdge, 'id'>;

interface IDrawingSize {
	width: number;
	height: number;
}
export interface IPosition {
	x: number;
	y: number;
}

export interface ISchemaClickData {
	nodes: (number | string)[];
	edges: (number | string)[];
	event: {
		type: 'tap' | 'panend' | 'panstart' | 'doubletap' | 'contextmenu';
	};
	pointer: {
		DOM: IPosition;
		canvas: IPosition;
	};
}

export const SCHEME_NODE_MIN_SIZE = 25;
export const SCHEME_NODE_INIT_SIZE = 40;
// Опції по замовчанню для діаграми
export const DEFAULT_DRAWING_OPTIONS: Options = {
	nodes: {
		// borderWidth: 1,
		// borderWidthSelected: 3,
		// size: 25,
		color: {
			// border: 'gray',
			highlight: {
				border: 'red',
			},
		},
		scaling: {
			min: SCHEME_NODE_MIN_SIZE,
			max: SCHEME_NODE_INIT_SIZE,
		},
		font: {
			face: 'Roboto',
			color: 'black',
			// size: 20,
			// bold: {
			// 	size: 20,
			// },
		},
	},
	edges: {
		color: 'gray',
		width: 2,
		arrows: {
			to: {
				enabled: true,
				type: 'arrow',
			},
		},
	},
	interaction: {
		navigationButtons: true,
		hover: true,
	},
	// layout: {
	// 	randomSeed: 5,
	// },
	physics: {
		barnesHut: {
			gravitationalConstant: -3000,
			// avoidOverlap: 0.1,
		},
		// 'barnesHut', 'repulsion', 'hierarchicalRepulsion', 'forceAtlas2Based'
		// solver: 'hierarchicalRepulsion',
	},
};

const SCHEME_INIT_ENTITY_COLOR = 'blue';
const SCHEME_ENTITY_LABEL_MAX_LENGTH = 25;

export const getEntityLabelForScheme = (
	title: TStringWithUndefined,
	isInit: boolean,
	maxLength = SCHEME_ENTITY_LABEL_MAX_LENGTH
) => splitTextByLength(title || '', maxLength, isInit ? '*' : '');

export type TEntityForNode = Omit<IEntityListItem, 'inserted_at' | 'description' | 'country'>;
/**
 * The function `getNodeForEntity` generates a node object for a given entity with specific properties
 * like id, label, shape, image, value, font, imagePadding, and mass.
 * @param {IEntityListItem} [entity] - This function `getNodeForEntity` takes an optional parameter
 * `entity` of type `IEntityListItem`. It extracts the `id`, `title`, `logo`, `entity_type`, and
 * `is_init` properties from the `entity` object. It then returns an object with properties like `id
 * @returns The function `getNodeForEntity` returns an object with properties `id`, `label`, `shape`,
 * `image`, `value`, `font`, `imagePadding`, and `mass`. The properties are derived from the `entity`
 * parameter, with default values provided if `entity` is undefined. The object is cast as type `Node`.
 */
export const getNodeForEntity = (entity?: TEntityForNode) => {
	const { id, title, logo, entity_type = 0, is_init = false } = entity || {};
	return {
		id,
		label: getEntityLabelForScheme(title, is_init),
		// shape: logo ? 'circularImage' : 'image',
		shape: 'circularImage',
		/**
		 * Для того, щоб можна було завантажувати зображення об'єктів зі схемою необхідно,
		 * щоб ці зображення були завантажені з одного з сайтом домену, в іншому випадку
		 * завантажити схему неможливо, позаяк виникає помилка:
		 * ! Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.
		 */
		// image: `/img/entityType/${entity_type}.svg`,
		image: getEntityLogoSrc(logo, entity_type),
		value: is_init ? 2 : 1,
		font: {
			color: is_init ? SCHEME_INIT_ENTITY_COLOR : undefined,
			multi: 'md',
			size: is_init ? SCHEME_NODE_INIT_SIZE / 2 : undefined,
			// bold: {
			// 	mod: 'bold',
			// },
		},
		imagePadding: logo ? undefined : 7,
		// mass: is_init ? 50 : 1,
		// color: is_init
		// 	? {
		// 			border: INIT_ENTITY_COLOR,
		// 	  }
		// 	: undefined,
		// borderWidth: schemeData.entities.entities[id]?.is_init ? 5 : 2,
		borderWidthSelected: is_init ? (logo ? 8 : 4) : logo ? 6 : 3,
		is_init,
		entity_type,
	} satisfies FullNode;
};

// const BOLD_EDGE_WIDTH = 4;
// const DASHED_EDGE = true;
const DASHED_EDGE = [10, 10];
const DOTTED_EDGE = [1, 4];
// const DOTTED_EDGE_BOLD = [1, 6];
const EDGE_ARROW = 'arrow';
const EDGES_OPTIONS: Record<string, EdgeOptions> = {
	1: {
		// 1	Володіє	Належить	true
		color: 'red',
		// width: BOLD_EDGE_WIDTH,
	},
	2: {
		// 2	Входить (відноситься), бере участь	Містить, залучено	true
		color: 'blue',
	},
	3: {
		// 3	Є осередком, структурним підрозділом	Складається з осередку, структурного підрозділу	true
		color: 'blue',
		dashes: DASHED_EDGE,
	},
	4: {
		// 4	Засновник, організатор, автор	Засновано, організовано, створено	true
		color: 'magenta',
		// width: BOLD_EDGE_WIDTH,
	},
	5: {
		// 5	Керує	Підпорядковується	true
		color: 'purple',
		// width: BOLD_EDGE_WIDTH,
	},
	6: {
		// 6	Конкурує (конфліктує)	Конкурує (конфліктує)	false
		// color: 'MediumVioletRed',
		color: 'olive',
		arrows: {
			from: { enabled: true, type: 'diamond' },
			to: { enabled: true, type: 'diamond' },
		},
	},
	7: {
		// 7	Контролюється, або є наближеним	Є контрольованим, або наближеним	true
		color: 'red',
		dashes: DASHED_EDGE,
	},
	8: {
		// 8	Лобіює інтереси	Є лобістом для	true
		color: 'DarkOrange',
	},
	9: {
		// 9	Має ділові стосунки	Має ділові стосунки	false
		color: 'DarkGreen',
		arrows: {
			from: { enabled: true, type: 'circle' },
			to: { enabled: true, type: 'circle' },
		},
	},
	10: {
		// 10	Має родинні, особисті зв'язки	Має родинні, особисті зв'язки	false
		color: 'DarkGreen',
		dashes: DOTTED_EDGE,
		arrows: {
			from: { enabled: true, type: 'circle' },
			to: { enabled: true, type: 'circle' },
		},
		// width: 2,
	},
	11: {
		// 11	Здійснював протиправне посягання (несанкціоноване втручання в роботу)	Піддавався протиправному посяганню (несанкціонованому втручанню в роботу)	true
		color: 'red',
		dashes: DOTTED_EDGE,
		// dashes: DOTTED_EDGE_BOLD,
		// width: BOLD_EDGE_WIDTH,
	},
	12: {
		// 12	Співпрацює, спілкується	Співпрацює, спілкується	false
		// dashes: DASHED_EDGE,
		color: '#848484',
		arrows: {
			from: { enabled: true, type: 'circle' },
			to: { enabled: true, type: 'circle' },
			// from: { enabled: true, type: EDGE_ARROW },
			// to: { enabled: true, type: EDGE_ARROW },
		},
	},
	13: {
		// 13	Бере участь у тіньових схемах і кримінальних аспектах функціонування	Є об'єктом тіньових схем і кримінальних аспектів функціонування	true
		color: 'black',
		// width: BOLD_EDGE_WIDTH,
	},
	14: {
		// 14	Фінансує	Фінансується	true
		// color: 'magenta',
		color: 'LimeGreen',
	},
};

/**
 * The function `getEdgeForRelationship` extracts relevant information from a relationship object and
 * returns an edge object with additional options based on the relationship type.
 * @param {IRelationshipListItem} relationship - The `getEdgeForRelationship` function takes in a
 * parameter `relationship` of type `IRelationshipListItem`, which contains the following properties:
 * @returns The function `getEdgeForRelationship` returns an object with properties `id`, `from`, `to`,
 * and additional properties from `EDGES_OPTIONS[relationship_type_id]`, all of which are combined into
 * an object of type `Edge`.
 */
export const getEdgeForRelationship = (relationship?: IRelationshipListItem) => {
	const { id, relationship_type_id, entity_id_1, entity_id_2 } = relationship || {};
	return {
		id,
		from: entity_id_1,
		to: entity_id_2,
		relationship_type: relationship_type_id,
		dashes: false,
		color: '#848484',
		arrows: {
			from: { enabled: false, type: EDGE_ARROW },
			to: { enabled: true, type: EDGE_ARROW },
		},
		...EDGES_OPTIONS[relationship_type_id || 0],
	} as FullEdge;
};

/**
 * The function `getDrawingSize` calculates the width and height of a drawing based on the bounding
 * boxes of nodes in a network.
 * @param {Network} network - The `network` parameter is an object representing a network in a graph
 * visualization library. It likely contains information about the structure and layout of the network,
 * such as nodes, edges, and their positions.
 * @param {DataSetFullNodes} nodes - The `nodes` parameter in the `getDrawingSize` function is of type
 * `DataSetFullNodes`. It contains information about the nodes in a network, such as their positions
 * and other relevant data.
 * @returns The function `getDrawingSize` returns an object with properties `width` and `height`,
 * representing the dimensions of the drawing based on the bounding boxes of nodes in the network.
 */
const getDrawingBoundaries = (network: Network, nodes: DataSetFullNodes) => {
	const ids = nodes.getIds();
	const boundaries = ids.map((id) => network.getBoundingBox(id));
	const x = boundaries.map((b) => [b.left, b.right]).flat() as number[];
	const y = boundaries.map((b) => [b.top, b.bottom]).flat() as number[];
	// const width = Math.max(...x) - Math.min(...x);
	// const height = Math.max(...y) - Math.min(...y);
	return {
		leftTop: { x: Math.min(...x), y: Math.min(...y) },
		rightBottom: { x: Math.max(...x), y: Math.max(...y) },
	};
};

const CANVAS_MAX_DOWNLOAD_SIZE = 15000;
/**
 * The function `getCanvasDownloadingSize` calculates the width and height of a canvas for downloading
 * based on the input size and scale.
 * @param {IDrawingSize}  - The `getCanvasDownloadingSize` function takes two parameters:
 * @param {number} scale - The `scale` parameter is a number that determines the scaling factor to be
 * applied to the width and height of the canvas when calculating the downloading size. It is used to
 * resize the canvas dimensions before downloading.
 * @returns An object containing the width and height properties is being returned. The width is
 * calculated as the minimum value between the product of the input width and scale, and the constant
 * CANVAS_MAX_DOWNLOAD_SIZE, concatenated with 'px'. The height is calculated in a similar way using
 * the input height and scale.
 */
const getCanvasDownloadingSize = ({ width, height }: IDrawingSize, scale: number) => {
	return {
		width: Math.min(width * scale, CANVAS_MAX_DOWNLOAD_SIZE) + 'px',
		height: Math.min(height * scale, CANVAS_MAX_DOWNLOAD_SIZE) + 'px',
	};
};

export const downloadScheme = async (
	network: Network,
	nodes: DataSetFullNodes,
	edges: DataSetFullEdges,
	schemeContainer: HTMLElement,
	scalingFactor: number,
	relationshipTypes: IDictionary<number, IRelationshipTypeDic>
) => {
	const canvas = schemeContainer.querySelector('canvas');

	if (!canvas) return;
	// const originalCanvasSize = { width: canvas.width, height: canvas.height };
	// const ctx = canvas.getContext('2d');

	// const drawingSize = getOldDrawingSize(network?.getPositions());
	// console.log(drawingSize);

	// console.log(canvasDownloadSize);
	network.unselectAll();
	network.setOptions({ physics: { enabled: false } });
	drawLegend(network, nodes, edges, relationshipTypes);

	const { leftTop, rightBottom } = getDrawingBoundaries(network, nodes);
	const drawingSize: IDrawingSize = { width: rightBottom.x - leftTop.x, height: rightBottom.y - leftTop.y };
	const canvasDownloadSize = getCanvasDownloadingSize(drawingSize, scalingFactor);
	network.setSize(canvasDownloadSize.width, canvasDownloadSize.height);
	network.fit({ maxZoomLevel: 6 });
	// console.log({ scale: network?.getScale() });

	// await promiseTimeout(scalingFactor * 1000);
	await promiseTimeout(1000);
	const png = canvas.toDataURL();
	loadImage(png, 'test.png');

	// await promiseTimeout(1000);
	// network?.setSize(originalCanvasSize.width + 'px', originalCanvasSize.height + 'px');
	removeLegendElements();
	network.setSize('100%', '100%');
	network.setOptions({ autoResize: true, physics: { enabled: true } });
	network.fit({ maxZoomLevel: 6 });
	// network?.setOptions({ physics: { enabled: true } });
	// network?.redraw();

	// network?.setOptions({ autoResize: true } as Options);
	// network?.redraw();
	// network = new Network(
	// 	schemeContainer,
	// 	{ nodes, edges },
	// 	{ ...options }
	// 	// { ...options, layout: { improvedLayout: schemeData.entities.ids.length < 100 } }
	// );
};

export const addEntityIfNotExists = (entity: TEntityForNode) => {
	const node = globalNetworkState?.nodes.get(entity.id);
	if (node) return;
	globalNetworkState?.nodes.add(getNodeForEntity(entity));
};

export const upsertNode = (entity: TEntityForNode) => {
	globalNetworkState?.nodes.update(getNodeForEntity(entity));
};

export const removeNodeIfObsolete = (nodeId?: IdType) => {
	if (!nodeId) return;
	const edges = globalNetworkState?.network.getConnectedEdges(nodeId);
	if (edges?.length !== 0) return;
	const isInit = globalNetworkState?.nodes.get(nodeId)?.is_init;
	if (isInit) return;
	globalNetworkState?.nodes.remove(nodeId);
};

export const upsertEdge = (link: IRelationshipListItem, entity1?: TEntityForNode, entity2?: TEntityForNode) => {
	if (!entity1 || !entity2) return;
	addEntityIfNotExists(entity1);
	addEntityIfNotExists(entity2);
	// if (!globalNetworkState?.edges.get(link.id)) {
	// 	const edge = getEdgeForRelationship(link);
	// 	globalNetworkState?.edges.add(edge);
	// }
	const edge = getEdgeForRelationship(link);
	globalNetworkState?.edges.update(edge);
};

export const removeEdge = (edgeId: IdType) => {
	const edge = globalNetworkState?.edges.get(edgeId);
	if (!edge) return;
	const { from, to } = edge;
	globalNetworkState?.edges.remove(edgeId);
	removeNodeIfObsolete(from);
	if (from !== to) removeNodeIfObsolete(to);
};

export const selectAndFocusNode = (nodeId: IdType) => {
	globalNetworkState?.network.selectNodes([nodeId]);
	globalNetworkState?.network.focus(nodeId);
};

const removeLegendElements = () => {
	const removeElements = (dataset: DataSet<any, 'id'>) => {
		const idForRemove = dataset.getIds().filter((id) => typeof id === 'string' && id.startsWith('legend'));
		dataset.remove(idForRemove);
	};
	if (!globalNetworkState) return;
	const { nodes, edges } = globalNetworkState;
	removeElements(edges);
	removeElements(nodes);
};

export const drawLegend = (
	network: Network,
	nodes: DataSetFullNodes,
	edges: DataSetFullEdges,
	relationshipTypes: IDictionary<number, IRelationshipTypeDic>
) => {
	const { leftTop, rightBottom } = getDrawingBoundaries(network, nodes);
	const uniqueRelationshipTypes = [
		...new Set(edges.getIds().map((id) => edges.get(id)?.relationship_type)),
	] as number[];

	const EDGES_PER_ROW = 7;
	const EDGE_LENGTH = 140;
	const X_GAP = 20;
	const Y_GAP = 50;
	const xCount = Math.min(EDGES_PER_ROW, uniqueRelationshipTypes.length);
	const legendLength = xCount * EDGE_LENGTH + (xCount - 1) * X_GAP;
	const legendXBasis = leftTop.x + Math.round((rightBottom.x - leftTop.x - legendLength) / 2);
	const legendYBasis = rightBottom.y + Y_GAP;

	let xCurrent = legendXBasis;
	let yCurrent = legendYBasis;
	// Draw nodes
	for (let index = 0; index < uniqueRelationshipTypes.length; index++) {
		const typeId = uniqueRelationshipTypes[index];
		nodes.add([
			{
				id: `legend_${typeId}_from`,
				shape: 'dot',
				size: 0,
				fixed: true,
				mass: 0.001,
			},
			{
				id: `legend_${typeId}_to`,
				shape: 'dot',
				size: 0,
				fixed: true,
				mass: 0.001,
			},
		] as any);
		network.moveNode(`legend_${typeId}_from`, xCurrent, yCurrent);
		network.moveNode(`legend_${typeId}_to`, xCurrent + EDGE_LENGTH, yCurrent);
		if ((index + 1) % EDGES_PER_ROW === 0) {
			xCurrent = legendXBasis;
			yCurrent += Y_GAP;
		} else {
			xCurrent += EDGE_LENGTH + X_GAP;
		}
	}
	// Draw edges
	for (const typeId of uniqueRelationshipTypes) {
		const baseEdge = getEdgeForRelationship({
			id: `legend_${typeId}`,
			relationship_type_id: typeId,
			entity_id_1: `legend_${typeId}_from`,
			entity_id_2: `legend_${typeId}_to`,
		} as any);
		const edge = {
			...baseEdge,
			label: getEntityLabelForScheme(relationshipTypes[typeId]?.title, false, 30),
			font: {
				align: 'top',
				vadjust: -12,
				size: 10,
				strokeWidth: 0,
			},
			smooth: false,
			relationship_type: typeId,
		} as FullEdge;
		edges.add(edge);
	}
};
