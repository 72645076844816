import styled from 'styled-components';
import EntityImage from './components/EntityImage';

interface IGridByRowsProps {
	$gap?: string;
	$baseSize: string;
}
export const GridAutoFill = styled.div<IGridByRowsProps>`
	display: grid;
	grid-gap: ${(props) => props.$gap || '1em'};
	grid-auto-flow: row;
	grid-template-columns: ${(props) =>
		`repeat(auto-fill, minmax(var(--grid-auto-fill-width, ${props.$baseSize}), 1fr))`};
`;

interface IClampedText {
	$linesCount: number;
	$lineHeight?: number;
}
export const ClampedText = styled.div<IClampedText>`
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.$linesCount};
	/* line-clamp: 2; */
	-webkit-box-orient: vertical;
	overflow: hidden;
	line-height: ${({ $lineHeight = 1.4 }) => $lineHeight + 'em'};
	max-height: ${({ $lineHeight = 1.4, $linesCount }) => $lineHeight * $linesCount + 'em'};
`;

export const EntityLogo = styled(EntityImage)<{ $size?: string }>`
	--entity-size: ${({ $size = '50px' }) => $size};
`;
