import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import { BsPencilSquare, BsPersonFillCheck, BsPersonFillSlash } from 'react-icons/bs';
import NullSpan from '../../../components/NullSpan';
import { IUser } from '../../../interfaces/users';

interface IUserItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	user?: IUser;
	unitCode?: string;
	managerEmail?: string;
}
const UserItem = ({ user, unitCode, managerEmail }: IUserItemProps) => {
	// Ця строка більше потрібна для відповідності типам
	if (!user) return null;

	return (
		<tr>
			<td>
				<Badge bg="secondary">{user.id}</Badge>
			</td>
			<td id={`user_${user.id}`}>{user.email}</td>
			<td>
				{user.is_active ? (
					<BsPersonFillCheck className="text-success icon-lg" />
				) : (
					<BsPersonFillSlash className="text-secondary icon-lg" />
				)}
			</td>
			<td>{unitCode}</td>
			<td>{managerEmail || <NullSpan />}</td>
			<td>
				<Button variant="link" size="sm" data-user-id={user.id} className="btn-edit p-0" title="Редагувати">
					<BsPencilSquare />
					<span className="ms-1 d-none d-lg-inline">Редагувати</span>
				</Button>
			</td>
		</tr>
	);
};

export default UserItem;
