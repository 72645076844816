import { Button, Form, FormControlProps, InputGroup } from 'react-bootstrap';

interface IDateWithNullProps extends FormControlProps {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onClear: (newValue: string | null) => void;
	dateIsValid?: boolean | null;
}
const DateWithNull = ({ className, onChange, onClear, disabled, dateIsValid = null, ...rest }: IDateWithNullProps) => {
	const onClearClick = () => onClear(null);

	return (
		<InputGroup className={className}>
			<Form.Control
				type="date"
				onChange={onChange}
				autoComplete="off"
				className={dateIsValid === null ? undefined : dateIsValid ? 'is-valid' : 'is-invalid bg-danger-subtle'}
				disabled={disabled}
				{...rest}
			/>
			<Button variant="outline-secondary" title="Очистити" onClick={onClearClick} disabled={disabled}>
				&empty;
			</Button>
		</InputGroup>
	);
};

export default DateWithNull;
