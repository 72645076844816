import { DEFAULTS } from '../../common';
import { IEntityEditMethod, IUpdateResponse, QUERY_TAGS, TMutatingRow } from '../../interfaces/common';
import { IRelationshipBase, IRelationshipObject } from '../../interfaces/relationship';
import { photonApi } from './photonApi';

type TMutatingRelationship = TMutatingRow<IRelationshipBase, 'id'>;
export type TRelationshipUpdate = TMutatingRelationship & { projectId: number; images?: string[] };

const relationshipApi = photonApi.injectEndpoints({
	endpoints: (builder) => ({
		relationship: builder.query<IRelationshipObject, string>({
			query: (relationshipId) => `relationship/${relationshipId}`,
			providesTags: (result, error, relationshipId) => [{ type: QUERY_TAGS.relationship, id: relationshipId }],
		}),
		editRelationship: builder.mutation<
			IRelationshipObject & IUpdateResponse<string>,
			TRelationshipUpdate & IEntityEditMethod
		>({
			query: ({ method, ...rest }) => ({
				url: 'edit/relationship',
				method,
				body: rest,
			}),
			invalidatesTags: (result, error, { method, id, projectId }) => [
				{ type: QUERY_TAGS.relationship, id: method === DEFAULTS.httpMethod.put ? id : 'list' },
				{ type: QUERY_TAGS.project_relationship, id: projectId },
			],
		}),
	}),
});

export const { useRelationshipQuery, useEditRelationshipMutation } = relationshipApi;
