import { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import Select, { ActionMeta, SingleValue } from 'react-select';
import { DEFAULTS } from '../common';
import { IDictionary, ISelectOption, TObjectType } from '../interfaces/common';
import Asterisk from './Asterisk';

// Тут інтерфейс не виведено окремо, тому що в ньому використовується generic T
const CommonSelector = <T extends TObjectType>({
	labelName,
	valueName,
	onItemChange,
	selectedId,
	allItems,
	containerClassName,
	fieldName,
	label,
	required = true,
	disabled,
	placeholder,
	...rest
}: {
	// Тут name - це назва поля об'єкта, а value - ідентифікатор обраного елемента
	onItemChange?: (value: number) => void;
	selectedId: number | null;
	containerClassName?: string;
	className?: string;
	allItems: IDictionary<number, T>;
	label?: string | JSX.Element;
	required?: boolean;
	fieldName: string;
	labelName: keyof T;
	valueName: keyof T;
	disabled?: boolean;
	placeholder?: string;
}) => {
	const options: ISelectOption[] = useMemo(
		() =>
			Object.values(allItems)
				.map((item) => ({
					label: item ? item[labelName] : '',
					value: item ? item[valueName] : '',
				}))
				.sort((item1, item2) => (item1.label < item2.label ? -1 : 1)),
		[allItems, labelName, valueName]
	);

	const selectedOption: ISelectOption = useMemo(() => {
		const item = allItems[selectedId ?? -1];
		return item
			? {
					label: item[labelName] ?? '',
					value: item[valueName] ?? '',
			  }
			: { label: 'null', value: '' };
	}, [allItems, selectedId, labelName, valueName]);

	const onChange = (value: SingleValue<ISelectOption>, actionMeta: ActionMeta<ISelectOption>) => {
		if (onItemChange && value) onItemChange(parseInt(value.value));
	};

	return (
		<Form.Group className={containerClassName}>
			<label className="form-label">
				{label} {required && <Asterisk />}
			</label>
			<Select
				classNamePrefix="themed-select"
				isSearchable
				options={options}
				value={selectedOption.value !== '' ? selectedOption : undefined}
				onChange={onChange}
				placeholder={placeholder}
				noOptionsMessage={() => DEFAULTS.noMatch}
				name={fieldName}
				required={required}
				isDisabled={disabled}
				{...rest}
			/>
		</Form.Group>
	);
};

export default CommonSelector;
