import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { BsPersonAdd } from 'react-icons/bs';
import { FixedDotSpinner } from '../../../components/DotSpinner';
import FetchError from '../../../components/FetchError';
import PageTitle from '../../../components/PageTitle';
import TextIcon from '../../../components/TextIcon';
import WithUnits, { IWithUnits } from '../../../components/hoc/withUnits';
import { useDebounce } from '../../../hooks';
import { moveControlToScreenCenter } from '../../../scrolls';
import { getControlData } from '../../../utils';
import { useUserListQuery } from '../../services/manage';
import UserDialog from './UserDialog';
import UserItem from './UserItem';

const UsersManageLayout = ({ units }: IWithUnits) => {
	const [selectedId, setSelectedId] = useState<number | null>(null);
	const { data, error, isFetching } = useUserListQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});
	const { term, onTermChange, debouncedValue } = useDebounce('');
	const [filteredUsers, setFilteredUsers] = useState<number[]>();

	useEffect(() => {
		const filter = debouncedValue.toLowerCase();
		setFilteredUsers(
			(filter.trim() === '' ? data?.ids : data?.ids.filter((id) => data.entities[id]?.email.includes(filter))) || []
		);
	}, [debouncedValue, data]);

	const onEditButtonClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
		const userId = getControlData(event.target, '.btn-edit', 'userId');
		if (userId === undefined) return;
		event.stopPropagation();
		setSelectedId(parseInt(userId));
	}, []);

	const onCloseDialog = useCallback((userId?: number) => {
		setSelectedId(null);
		if (!userId) return;
		setTimeout(() => {
			moveControlToScreenCenter(document.getElementById(`user_${userId}`), true);
		}, 100);
	}, []);

	return (
		<>
			<PageTitle title="Користувачі" />
			<Row>
				<Col className="hstack gap-1 align-items-center">
					<h3 className="m-0">
						Кількість знайдених користувачів —
						<span className="ms-1 fw-bold text-info">{data ? filteredUsers?.length ?? '...' : ''}</span>
					</h3>
					<Button className="ms-auto btn-edit" data-user-id={0} onClick={onEditButtonClick}>
						<TextIcon Icon={BsPersonAdd} size="lg">
							Додати
						</TextIcon>
					</Button>
				</Col>
			</Row>
			<Form.Group as={Row} className="mt-3">
				<Form.Label column sm={3} lg={2}>
					Строка фільтру
				</Form.Label>
				<Col sm={9} lg={10}>
					<Form.Control
						type="text"
						placeholder="фільтрація проводиться по email користувача"
						value={term}
						onChange={onTermChange}
					/>
				</Col>
			</Form.Group>
			<Row className="mt-1">
				{error && (
					<Col xs={12}>
						<FetchError error={error} />
					</Col>
				)}
				<Col className="position-relative">
					<Table striped onClick={onEditButtonClick} responsive>
						<thead>
							<tr>
								<th>ID</th>
								<th>Email</th>
								<th>Активний</th>
								<th>Підрозділ</th>
								<th>Контролер</th>
								<th>Дії</th>
							</tr>
						</thead>
						{data && filteredUsers && (
							<tbody>
								{filteredUsers.map((id) => {
									const user = data.entities[id];
									if (!user) return null;
									return (
										<UserItem
											key={id}
											user={user}
											unitCode={units.entities[user.unit_id || -1]?.code}
											managerEmail={data.entities[user.manager || -1]?.email}
										/>
									);
								})}
								<tr className="show-only-child">
									<td colSpan={5}>&empty; Відсутні записи, що задовольняють фільтру</td>
								</tr>
							</tbody>
						)}
					</Table>
					{isFetching && <FixedDotSpinner>Завантаження списку користувачів...</FixedDotSpinner>}
				</Col>
			</Row>
			{selectedId !== null && (
				<UserDialog
					user={data?.entities[selectedId]}
					onClose={onCloseDialog}
					users={data?.entities || {}}
					units={units}
				/>
			)}
		</>
	);
};

export default WithUnits(UsersManageLayout);
