import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { BsPlusCircle } from 'react-icons/bs';
import { FixedDotSpinner } from '../../../components/DotSpinner';
import FetchError from '../../../components/FetchError';
import PageTitle from '../../../components/PageTitle';
import TextIcon from '../../../components/TextIcon';
import WithUnits, { IWithUnits } from '../../../components/hoc/withUnits';
import { useDebounce } from '../../../hooks';
import { moveControlToScreenCenter } from '../../../scrolls';
import { getControlData } from '../../../utils';
import UnitDialog from './UnitDialog';
import UnitItem from './UnitItem';

const UsersManageLayout = ({ units, unitsError, unitsIsFetching }: IWithUnits) => {
	const [selectedId, setSelectedId] = useState<number | null>(null);
	const { term, onTermChange, debouncedValue } = useDebounce('');
	const [filteredUnits, setFilteredUnits] = useState<number[]>();

	useEffect(() => {
		const filter = debouncedValue.toLowerCase();
		setFilteredUnits(
			(filter.trim() === ''
				? units.ids
				: units.ids.filter((id) => units.entities[id]?.code.toLowerCase().includes(filter))) || []
		);
	}, [debouncedValue, units]);

	const onEditButtonClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
		const unitId = getControlData(event.target, '.btn-edit', 'unitId');
		if (unitId === undefined) return;
		event.stopPropagation();
		setSelectedId(parseInt(unitId));
	}, []);

	const onCloseDialog = useCallback((unitId?: number) => {
		setSelectedId(null);
		if (!unitId) return;
		setTimeout(() => {
			moveControlToScreenCenter(document.getElementById(`unit_${unitId}`), true);
		}, 100);
	}, []);

	return (
		<>
			<PageTitle title="Підрозділи" />
			<Row>
				<Col className="hstack gap-1 align-items-center">
					<h3 className="m-0">
						Кількість знайдених підрозділів —
						<span className="ms-1 fw-bold text-info">{filteredUnits?.length ?? '...'}</span>
					</h3>
					<Button className="ms-auto btn-edit" data-unit-id={0} onClick={onEditButtonClick}>
						<TextIcon Icon={BsPlusCircle} size="lg">
							Додати
						</TextIcon>
					</Button>
				</Col>
			</Row>
			<Form.Group as={Row} className="mt-3">
				<Form.Label column sm={3} lg={2}>
					Строка фільтру
				</Form.Label>
				<Col sm={9} lg={10}>
					<Form.Control
						type="text"
						placeholder="фільтрація проводиться коду підрозділу"
						value={term}
						onChange={onTermChange}
					/>
				</Col>
			</Form.Group>
			<Row className="mt-1">
				{unitsError && (
					<Col xs={12}>
						<FetchError error={unitsError} />
					</Col>
				)}
				<Col className="position-relative">
					<Table striped onClick={onEditButtonClick} responsive>
						<thead>
							<tr>
								<th>ID</th>
								<th>Код</th>
								<th>Дії</th>
							</tr>
						</thead>
						{filteredUnits && (
							<tbody>
								{filteredUnits.map((id) => {
									const unit = units.entities[id];
									if (!unit) return null;
									return <UnitItem key={id} unit={unit} />;
								})}
								<tr className="show-only-child">
									<td colSpan={3}>&empty; Відсутні записи, що задовольняють фільтру</td>
								</tr>
							</tbody>
						)}
					</Table>
					{unitsIsFetching && <FixedDotSpinner>Завантаження списку підрозділів...</FixedDotSpinner>}
				</Col>
			</Row>
			{selectedId !== null && <UnitDialog unit={units.entities[selectedId]} onClose={onCloseDialog} />}
		</>
	);
};

export default WithUnits(UsersManageLayout);
