import { useCallback, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BsFolder } from 'react-icons/bs';
import { ConnectedProps, connect } from 'react-redux';
import { DEFAULTS } from '../../../common';
import { FixedDotSpinner } from '../../../components/DotSpinner';
import FetchError from '../../../components/FetchError';
import HeaderWithImage from '../../../components/HeaderWithImage';
import SearchPagination from '../../../components/Pagination';
import TextIcon from '../../../components/TextIcon';
import WithUnits, { IWithUnits } from '../../../components/hoc/withUnits';
import ImgComplete from '../../../img/undraw_complete_task_u2c3.svg';
import ImgVoid from '../../../img/undraw_void_-3-ggu.svg';
import { RootState } from '../../../store';
import { isPeriodValid } from '../../../utils';
import { useSearchProjectsQuery } from '../../services/project';
import {
	changeProjectSearchMetadataCurrent,
	changeProjectSearchMetadataProjects,
	changeProjectSearchPageNo,
} from '../projectSlice';
import RecentProject from '../recentProject';
import SearchProjectPanel from './SearchProjectPanel';

interface ISearchProjectLayoutProps extends PropsFromRedux, IWithUnits, React.AllHTMLAttributes<HTMLDivElement> {}
const SearchProjectLayout = ({
	units,
	params,
	searchMetadata,
	changeProjectSearchPageNo,
	changeProjectSearchMetadataCurrent,
	changeProjectSearchMetadataProjects,
}: ISearchProjectLayoutProps) => {
	const { data, error, isFetching } = useSearchProjectsQuery(params, {
		skip: !isPeriodValid(params.startDate, params.endDate),
	});

	useEffect(() => {
		if (!data) return;
		if (data.total !== undefined) changeProjectSearchMetadataProjects(data.total);
		else changeProjectSearchMetadataCurrent(params.pageNo);
	}, [data]); // eslint-disable-line

	const onPageChange = useCallback(
		(newPageNo: number) => changeProjectSearchPageNo(newPageNo),
		[changeProjectSearchPageNo]
	);

	const firstSerialNo = searchMetadata.currentPageNo * DEFAULTS.projectsPerPage + 1;
	return (
		<Container fluid={DEFAULTS.fluid}>
			<SearchProjectPanel disabled={isFetching} />
			{error && (
				<Row className="mt-2">
					<Col xs={12}>
						<FetchError error={error} />
					</Col>
				</Row>
			)}
			{data?.projects && searchMetadata.totalPages !== 0 && (
				<>
					<Row className="mb-3">
						<Col xs={12} className="h2 hstack gap-2 justify-content-between align-items-center">
							<span className="badge text-bg-info fs-6">
								{searchMetadata.totalPages !== 0 ? (
									<>
										стор. {searchMetadata.currentPageNo + 1} з {searchMetadata.totalPages}
									</>
								) : (
									<>&empty;</>
								)}
							</span>
							<TextIcon Icon={BsFolder}>Знайдені проекти</TextIcon>
							<span className="badge rounded-pill text-bg-info fs-6">{searchMetadata.totalProjects}</span>
						</Col>
						{/* <GridAutoFill className="col-12 align-items-start" $baseSize="450px"> */}
						<div className="col-12 vstack gap-4">
							{data.projects.map((project, index) => (
								<RecentProject
									key={project.project_id}
									project={project}
									unitsById={units.entities}
									serialNo={firstSerialNo + index}
								/>
							))}
						</div>
						{/* </GridAutoFill> */}
					</Row>
					{searchMetadata.totalPages - 1 === searchMetadata.currentPageNo && (
						<HeaderWithImage imgSrc={ImgComplete} alt="Остання сторінка" className="my-3" small />
					)}
					{1 < searchMetadata.totalPages && (
						<Row>
							<SearchPagination
								pageCount={searchMetadata.totalPages}
								activePageNo={searchMetadata.currentPageNo}
								onChangePageNo={onPageChange}
							/>
						</Row>
					)}
				</>
			)}
			{data && searchMetadata.totalPages === 0 && (
				<HeaderWithImage
					imgSrc={ImgVoid}
					// alt="Зображення відсутності результатів"
					headerClassName="text-info"
					title="За Вашим пошуковим запитом нічого не знайдено"
					className="col"
				/>
			)}
			{isFetching && <FixedDotSpinner>Пошук проектів...</FixedDotSpinner>}
		</Container>
	);
};

const mapState = (state: RootState) => ({
	params: state.project.projectSearchParams,
	searchMetadata: state.project.projectSearchMetadata,
});

const mapDispatch = {
	changeProjectSearchPageNo,
	changeProjectSearchMetadataCurrent,
	changeProjectSearchMetadataProjects,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WithUnits(SearchProjectLayout));
