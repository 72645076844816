import React from 'react';
import { Badge, Button, ButtonGroup, Modal } from 'react-bootstrap';
import { BsSave, BsXLg } from 'react-icons/bs';
import { TStringOrNumber } from '../interfaces/common';
import { AsteriskText } from './Asterisk';
import TextIcon from './TextIcon';

interface IDialogFooterProps extends React.AllHTMLAttributes<HTMLDivElement> {
	formId: string;
	entityId?: TStringOrNumber;
	disabled: boolean;
	isLoading: boolean;
	onClose: () => void;
}
const DialogFooter = ({ entityId, disabled, isLoading, formId, onClose, children }: IDialogFooterProps) => {
	return (
		<Modal.Footer className="justify-content-between">
			<AsteriskText />
			{entityId && <Badge bg="secondary">ID: {entityId}</Badge>}
			<ButtonGroup>
				{children}
				<Button variant="secondary" onClick={onClose} type="button">
					<TextIcon Icon={BsXLg}>Закрити</TextIcon>
				</Button>
				<Button disabled={disabled} type="submit" form={formId}>
					<TextIcon Icon={BsSave} className={isLoading ? 'animation-bounce' : undefined}>
						Зберегти
					</TextIcon>
				</Button>
			</ButtonGroup>
		</Modal.Footer>
	);
};

export default DialogFooter;
