import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { BsEye, BsFolderMinus, BsFolderPlus } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { DEFAULTS } from '../../common';
import ArrowDirection from '../../components/ArrowDirection';
import InProjectEntityPatch from '../../components/InProjectEntityPatch';
import TextIcon from '../../components/TextIcon';
import DescriptionText from '../../components/controls/DescriptionText';
import { IUserRights } from '../../interfaces/dicts';
import { IEntityBase } from '../../interfaces/entities';
import { EntityLogo } from '../../styledComponents';
import EntityTitleWithType from '../entities/EntityTitleWithType';
import { IEntityLink } from '../services/entity';

const HEADER_ICON_COMMON_CLASS_NAME = 'fs-4 flex-shrink-0';

interface IRelationshipCardProps extends React.AllHTMLAttributes<HTMLDivElement> {
	link: IEntityLink;
	directional: boolean;
	isProjectEditor?: boolean;
	unitTitle?: string;
	userRights?: IUserRights;
	inCurrentProject: boolean;
	entityInProject?: boolean;
}
const RelationshipCard = ({
	link,
	directional,
	isProjectEditor,
	unitTitle,
	userRights,
	inCurrentProject,
	entityInProject,
}: IRelationshipCardProps) => {
	const [complementaryEntity, setComplementaryEntity] = useState<IEntityBase>({
		id: link.complementary_entity_id,
		title: link.entity_title,
		entity_type: link.entity_type_id,
		logo: link.entity_logo,
	} as IEntityBase);

	useEffect(() => {
		setComplementaryEntity({
			id: link.complementary_entity_id,
			title: link.entity_title,
			entity_type: link.entity_type_id,
			logo: link.entity_logo,
		} as IEntityBase);
	}, [link]);

	const controlId = `linkCard_${link.id}`;
	return (
		<Card className={inCurrentProject ? 'text-bg-warning card-in-project' : undefined} id={controlId}>
			<Card.Header className="hstack gap-2 align-items-start lh-sm align-items-center">
				<ArrowDirection
					directional={directional}
					direct={link.direct_order}
					className={HEADER_ICON_COMMON_CLASS_NAME}
				/>
				<div className="hstack align-items-center gap-2 position-relative me-auto">
					<EntityLogo entity={complementaryEntity} $size="36px" className="rounded" />
					<Link
						to={`../${DEFAULTS.routes.entity}/${complementaryEntity.id}`}
						state={{ ...DEFAULTS.origins.search, controlId }}
						className="stretched-link link-body-emphasis me-auto"
					>
						<EntityTitleWithType
							className="h6 mb-0"
							title={complementaryEntity.title}
							typeId={complementaryEntity.entity_type}
						/>
					</Link>
				</div>
				{entityInProject && (
					<InProjectEntityPatch className={HEADER_ICON_COMMON_CLASS_NAME} entityId={link.complementary_entity_id} />
				)}
			</Card.Header>
			<Card.Body className="position-relative">
				<DescriptionText
					description={link.description}
					maxHeight="150px"
					iconOnly
					scrollPosition="center"
					variant="outline-secondary"
				/>
			</Card.Body>
			<Card.Footer title="Ким та коли створено зв'язок" className="hstack justify-content-between lh-sm gap-1">
				<span className="text-truncate">
					<span>{dayjs.utc(link.created_at).local().format('L')}</span> • {link.creator_email}
					<span className="text-muted ms-1">({unitTitle})</span>
				</span>
				<div className={classNames(HEADER_ICON_COMMON_CLASS_NAME, 'hstack gap-1 mt-n1')}>
					{/* {userRights?.unitId === link.creator_unit_id && ( */}
					<Link
						to={`../${DEFAULTS.routes.relationship}/${link.id}`}
						state={{ ...DEFAULTS.origins.search, controlId }}
						className="link-secondary"
					>
						<BsEye title="Перегляд" />
					</Link>
					{/* )} */}
					{isProjectEditor && (
						<Button
							variant="link"
							className={classNames(
								'fs-4 p-0 link-action text-nowrap me-n11 border-0',
								inCurrentProject ? 'link-danger' : 'link-success'
							)}
							title={inCurrentProject ? 'Вилучити з проекту' : 'Додати до проекту'}
							data-id={link.id}
							data-action={inCurrentProject ? 'remove' : 'add'}
						>
							<TextIcon Icon={inCurrentProject ? BsFolderMinus : BsFolderPlus} />
							{/* <TextIcon Icon={inCurrentProject ? BsEyeSlashFill : BsEyeFill} /> */}
							{/* <TextIcon Icon={inCurrentProject ? BsDash : BsPlus} className="ms-n1" /> */}
						</Button>
					)}
				</div>
				{/* {inCurrentProject && <span>{dayjs(link.inserted_at).format('L')}</span>} */}
			</Card.Footer>
		</Card>
	);
};

export default RelationshipCard;
